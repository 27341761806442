import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

interface PropTypes {
  inputStyles?: string;
}
const Search: React.FC<PropTypes> = ({ inputStyles }) => {
  return (
    <div
      className={`${
        inputStyles ? inputStyles : "bg-white"
      } w-full flex items-center gap-3 border-[0.5px] border-gray-300 rounded-lg px-2 py-2`}
    >
      <MagnifyingGlassIcon className='size-5' />
      <input className={`outline-none`} placeholder='Search' />
    </div>
  );
};

export default Search;
