import { BookmarkIcon, EyeIcon, MapPinIcon } from "@heroicons/react/24/outline";
import { BookmarkIcon as BookmarkSolidIcon } from "@heroicons/react/24/solid";
import { chat_profile, gigs_product_image } from "assets/images";
import Button from "components/Button";
import { useState } from "react";
interface PropsType {
  connectWithBuyer?: boolean;
  cancelled?: boolean;
}
const GigsListItem: React.FC<PropsType> = ({ connectWithBuyer, cancelled }) => {
  const [bookmark, setBookmark] = useState(false);
  return (
    <div className='grid grid-cols-12 p-3 gap-5 border border-gray-300 rounded-lg'>
      <img src={gigs_product_image} className='col-span-3' alt="gigs product"/>
      <div className='flex-grow col-span-8'>
        <div className='flex justify-between text-sm'>
          <div className='flex items-center gap-2'>
            <img src={chat_profile} className='size-8' alt="chat profile"/>
            Henry
          </div>
          <div>10d ago</div>
        </div>
        <div className='mt-3 flex flex-col gap-2 items-start'>
          <h3 className='text-lg font-bold'>Nike Shoe</h3>
          <p className='text-sm'>
            Looking for Nike Air Max, size 10, color orange. Must be brand new
            and in original packaging. Preferably from a seller located in
            Lagos.
          </p>
          <Button
            disabled={true}
            label='Budget: $150-200'
            containerStyles='bg-mms-green-medium hover:bg-mms-green-semi-dark disabled:bg-mms-pink-semi-light'
            textStyles='text-black text-sm'
          />
        </div>
        <div className='mt-4 flex items-center gap-5'>
          {cancelled === true && (
            <Button
              onClick={() => {}}
              label='Cancelled'
              containerStyles='bg-mms-red-medium hover:bg-mms-red-semi-dark active:bg-mms-red-dark disabled:bg-mms-gray-disabled'
              textStyles='text-white text-sm'
            />
          )}
          {connectWithBuyer !== undefined && (
            <Button
              disabled={!connectWithBuyer}
              onClick={() => {}}
              label='Connect with buyer'
              containerStyles='bg-mms-green-medium hover:bg-mms-green-semi-dark active:bg-mms-green-dark disabled:bg-mms-gray-disabled'
              textStyles='text-white text-sm'
            />
          )}

          <Button
            isActive={false}
            onClick={() => {}}
            label='Product'
            inActiveStyles='bg-mms-green-semi-light hover:bg-mms-green-semi-dark active:bg-mms-green-dark disabled:bg-mms-gray-disabled'
            inActiveTextStyles='text-white text-xs'
            containerStyles='bg-mms-green-medium hover:bg-mms-green-semi-dark active:bg-mms-green-dark disabled:bg-mms-gray-disabled'
            textStyles='text-white text-sm'
          />
          <div className='flex items-center gap-1 text-sm'>
            <EyeIcon className='size-6 text-mms-green-medium' />
            <span>68</span>
          </div>
          <div className='flex items-center gap-1 text-sm'>
            <MapPinIcon className='size-6 text-mms-green-medium' />
            <span>Lagos, Nigeria</span>
          </div>
          <div className='flex items-center gap-1 text-sm'>
            <span>Bid: 30</span>
          </div>
          {bookmark ? (
            <BookmarkSolidIcon
              className={"size-6 text-mms-green-medium cursor-pointer"}
              onClick={() => setBookmark(!bookmark)}
            />
          ) : (
            <BookmarkIcon
              className={"size-6 text-mms-green-medium cursor-pointer"}
              onClick={() => setBookmark(!bookmark)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default GigsListItem;
