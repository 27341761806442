import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { product_image } from "assets/images";
import Budget from "components/Budget";
import Button from "components/Button";
import GeneralTopSearchWithNotification from "components/GeneralTopSearchWithNotification";
import LocationSearch from "components/LocationSearch";
import NavigationSideBar from "components/NavigationSideBar";
import { useNavigate } from "react-router-dom";

const BoostProduct: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className='flex-grow h-full min-h-screen'>
      <div className='h-full min-h-screen pl-5 pr-10 py-2 bg-mms-gray-light'>
        <div className='grid grid-cols-10 gap-10 items-start'>
          <div className='col-span-7 mt-5 flex flex-col'>
            <div className='bg-white p-5'>
              <div
                className='flex items-center gap-2 text-sm cursor-pointer'
                onClick={() => navigate("/products")}
              >
                <ArrowLeftIcon className='size-5' />
                <span>Back</span>
              </div>
              <div className='mt-3'>
                <h3 className='font-bold text-xl'>Boost Product Post</h3>
                <p className='text-sm text-gray-600'>
                  Increase your product's visibility and reach more buyers by
                  boosting your listing.
                </p>
              </div>
            </div>
            <LocationSearch />
            <Budget />
          </div>
          <div className='col-span-3 bg-mms-green-medium mt-5 flex flex-col p-3 rounded-md '>
            <p className='text-white'>Review Purchase</p>
            <div className='bg-white flex items-center rounded-sm p-2 gap-2'>
              <img
                alt='product'
                src={product_image}
                className='w-14 h-14 object-cover object-top rounded-md'
              />
              <div className='text-sm'>
                <p className='line-clamp-1'>Long Sleeve Overshirt, Khaki, 6</p>
                <p>Categories: Fashion</p>
                <p>$300</p>
              </div>
            </div>
            <div className='space-y-4 mt-5'>
              <p className='flex gap-5 justify-between items-center text-white pb-4 border-b-[0.5px] border-white'>
                <span>Duration</span>
                <span>10 Days</span>
              </p>
              <p className='flex gap-5 justify-between items-center text-white pb-4 border-b-[0.5px] border-white'>
                <span>Subtotal</span>
                <span>₦15,000</span>
              </p>
              <p className='flex gap-5 justify-between items-center text-white pb-4 border-b-[0.5px] border-white'>
                <span>VAT@7.50%</span>
                <span>₦1125</span>
              </p>
            </div>
            <div className='text-white my-4'>
              <p>Total Amount</p>
              <p className='font-bold text-3xl '>₦15,000</p>
            </div>
            <Button
              label='Boost'
              type='submit'
              containerStyles='w-full bottom-5 right-10 bg-mms-green-semi-light hover:bg-mms-green-semi-dark active:bg-mms-green-dark disabled:bg-mms-gray-disabled text-mms-green-dark text-xl hover:text-white'
              textStyles=''
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoostProduct;
