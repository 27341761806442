import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
const DashboardEarnings = () => {
  return (
    <div className='grid grid-cols-12 gap-5 mt-5'>
      <div className='col-span-4 bg-white rounded-lg shadow-sm pt-7 pb-5 pr-4 pl-6'>
        <div>
          <h3 className='font-bold'>Today's Sales</h3>
          <p className='text-sm text-gray-500 mt-1'>Sales Summary</p>
        </div>
        <h3 className='font-bold text-3xl mt-2'>₦823,900.87</h3>
        <p className='mt-3 text-sm'>Profit is 48% More than last Month</p>
        <div className='w-52 h-52 mt-5 mx-auto'>
          <CircularProgressbar
            styles={{
              path: {
                stroke: `#35A162`, // Path color (the progress)
              },
              trail: {
                stroke: "#C0E2CE", // Trail color (the background)
              },
              text: {
                fill: "#000", // Text color (percentage)
                fontSize: "16px",
                fontWeight: "700",
              },
            }}
            value={80}
            strokeWidth={20}
            text={`${80}%`}
          />
        </div>
      </div>
      <div className='col-span-8 bg-white rounded-lg shadow-md py-5 px-4'></div>
    </div>
  );
};

export default DashboardEarnings;
