import AdsListingItem from "./AdsListingItem";

const PausedAds = () => {
  return (
    <div className='mt-10 bg-white p-5 rounded-lg'>
      <div className='bg-mms-green-medium text-sm font-bold text-white grid grid-cols-7 items-center rounded-md p-2 mb-2'>
        <p className='col-span-2'>Product Information</p>
        <p>Ads Status</p>
        <p>Ads Period</p>
        <p>Ads Date</p>
        <p>Budget</p>
        <p>Action</p>
      </div>
      <AdsListingItem initialAction='Paused' />
      <AdsListingItem initialAction='Paused' />
      <AdsListingItem initialAction='Paused' />
    </div>
  );
};

export default PausedAds;
