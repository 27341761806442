import { ChevronRightIcon } from "@heroicons/react/24/outline";

interface PropsType {
  label: string;
  activeStyle: string;
  defaultStyle: string;
  activeTab: number;
  index: number;
  handleTabClick: (text: number) => void;
}
const SupportQuestion: React.FC<PropsType> = ({
  activeTab,
  activeStyle,
  defaultStyle,
  label,
  index,
  handleTabClick,
}) => {
  return (
    <div
      className={`${defaultStyle} ${
        activeTab === index && activeStyle
      } transition-all flex gap-3 items-center justify-between px-5 py-5 cursor-pointer`}
      onClick={() => handleTabClick(index)}
    >
      <div className='flex gap-3 items-center'>
        <div
          className={`${
            activeTab === index ? "bg-mms-green-medium" : "bg-mms-green-light"
          } w-5 h-5 rounded-full`}
        ></div>
        <p>{label}</p>
      </div>
      <ChevronRightIcon
        className={`${
          activeTab === index ? "text-mms-green-medium" : "text-mms-green-light"
        } size-5`}
      />
    </div>
  );
};

export default SupportQuestion;
