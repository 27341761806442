import ChatProfilePicture from "./ChatProfilePicture";

const MessageChat = () => {
  return (
    <div className='grid grid-cols-11 gap-1 mt-5 border-b-[0.5px] border-gray-400 p-2 cursor-pointer rounded-lg transition-all hover:bg-gray-200'>
      <ChatProfilePicture />
      <div className='col-span-7 place-self-start ml-2'>
        <p className='font-bold text-sm'>Tosin Makinde Stores</p>
        <p className='font-light text-xs text-gray-500'>
          Can you please confirm your order
        </p>
      </div>
      <div className='col-span-3 flex flex-col items-end gap-1'>
        <p className='text-xs font-light text-gray-800'>10mins ago</p>
        <div className='w-4 h-4 flex items-center justify-center rounded-full text-xs text-white bg-mms-green-medium'>
          1
        </div>
      </div>
    </div>
  );
};

export default MessageChat;
