import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import Button from "components/Button";
import FormField from "components/FormField";
import PopupOTP from "components/PopupOTP";
import { ErrorMessage, Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";

const initialValues: any = {
  account_holder_name: "",
  bank_name: "",
  bank_account_number: "",
};

const validationSchema = Yup.object({
  account_holder_name: Yup.string().required("Required"),
  bank_name: Yup.string().required("Required"),
  bank_account_number: Yup.string().required("Required"),
});

interface PropsType {
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
}

const AddPaymentAccount: React.FC<PropsType> = ({ setActiveTab }) => {
  const [popUp, setPopUp] = useState(false);
  return (
    <div className='flex flex-col col-span-7 min-h-96 bg-white rounded-lg p-5 border-2 border-gray-100'>
      <div className='mb-5 flex items-center gap-1'>
        <div
          className='bg-gray-300 p-1 cursor-pointer'
          onClick={() => setActiveTab("payment_account")}
        >
          <ArrowLeftIcon className='size-4' />
        </div>
        <span>Back</span>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          console.log(values);
          actions.setSubmitting(false);
        }}
      >
        {({ values, errors }) => (
          <div>
            <h3 className='font-bold'>Add Payment Account</h3>
            <p className='text-sm text-gray-500 mt-1 italic'>
              Add a bank account to receive payment. Bank account details must
              match the information used to verify your business account.
            </p>
            <Form className='mt-5 flex flex-col w-full gap-3'>
              <div className=''>
                <FormField
                  id='account_holder_name'
                  name='account_holder_name'
                  label='Account Holder Name'
                  labelStyle="mb-1 font-bold relative after:content-['*'] after:text-red-500"
                  placeholder='Account holder name'
                />
                <ErrorMessage
                  name='account_holder_name'
                  component='div'
                  className='text-red-500 text-sm'
                />
              </div>
              <div>
                <FormField
                  id='bank_name'
                  name='bank_name'
                  label='Bank Name'
                  labelStyle="mb-1 font-bold relative after:content-['*'] after:text-red-500"
                  placeholder='Bank name'
                />
                <ErrorMessage
                  name='bank_name'
                  component='div'
                  className='text-red-500 text-sm'
                />
              </div>
              <div>
                <FormField
                  id='bank_account_number'
                  name='bank_account_number'
                  label='Bank Account Number'
                  labelStyle="mb-1 font-bold relative after:content-['*'] after:text-red-500"
                  placeholder='Bank account number'
                />
                <ErrorMessage
                  name='bank_account_number'
                  component='div'
                  className='text-red-500 text-sm'
                />
              </div>
              <Button
                label='Add payment method'
                loadingText='Creating Account'
                type='submit'
                containerStyles='self-end bg-mms-green-medium hover:bg-mms-green-semi-dark active:bg-mms-green-dark disabled:bg-mms-gray-disabled'
                textStyles='text-white'
              />
            </Form>
            {popUp && <PopupOTP email={values.email} setPopUp={setPopUp} />}
          </div>
        )}
      </Formik>
    </div>
  );
};

export default AddPaymentAccount;
