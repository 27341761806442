import { ChevronRightIcon } from "@heroicons/react/24/outline";
import React from "react";
interface PropsType {
  label: string;
  activeStyle: string;
  defaultStyle: string;
  activeTab: string;
  icon?: React.ReactNode;
  type?: string;
  handleTabClick: (text: string) => void;
}
const SettingTab: React.FC<PropsType> = ({
  activeTab,
  activeStyle,
  defaultStyle,
  label,
  icon,
  type,
  handleTabClick,
}) => {
  return (
    <div
      className={`${defaultStyle} ${
        activeTab.includes(label) && activeStyle
      } transition-all flex gap-3 items-center justify-between px-5 py-5 cursor-pointer`}
      onClick={() => handleTabClick(label)}
    >
      <div className='flex items-center gap-2'>
        {!(type === "delete") && icon}
        <p>{label}</p>
      </div>
      {!(type === "delete") && (
        <ChevronRightIcon
          className={`${
            activeTab.includes(label) ? "text-mms-green-dark" : "text-black"
          } size-5`}
        />
      )}
    </div>
  );
};

export default SettingTab;
