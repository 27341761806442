import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";

const LocationSearch: React.FC = () => {
  const [search, setSearch] = useState("");
  const [locations, setLocations] = useState<Array<string>>([]);

  const handleRemove = (idx: number) => {
    const updatedLocations = locations.filter((_, index) => index !== idx);
    setLocations(updatedLocations);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setLocations((prev) => [...prev, search]);
      setSearch("");
    }
  };
  return (
    <div className='bg-white p-5 flex flex-col gap-5'>
      <div>
        <h3 className='font-bold text-xl'>Audience</h3>
        <p className='text-sm text-gray-600'>Who should see your ad?</p>
      </div>
      <div>
        <h3 className='font-bold text-xl mb-2'>Location</h3>
        <div
          className={`bg-white w-full flex items-center gap-3 border-[0.5px] border-gray-300 rounded-lg px-2 py-3`}
        >
          <MagnifyingGlassIcon className='size-4 text-gray-500' />
          <input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={handleKeyPress}
            className={`outline-none`}
            placeholder='Input city, State or Country.'
          />
        </div>
        <div className='mt-3 flex items-center gap-5'>
          {locations?.length ? (
            locations.map((location, idx) => (
              <div
                key={idx}
                className='w-fit py-1 px-2 rounded-md text-sm text-mms-green-dark flex items-center gap-2 bg-mms-green-extra-light'
              >
                {location}

                <XMarkIcon
                  onClick={() => handleRemove(idx)}
                  className='w-4 cursor-pointer'
                />
              </div>
            ))
          ) : (
            <div className='text-sm text-gray-500'>No location selected</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LocationSearch;
