import AccountInformationForm from "../AccountInformationForm";

const AccountInformation = () => {
  return (
    <div className='col-span-7 min-h-96 bg-white rounded-lg p-5 border-2 border-gray-100'>
      <h3 className='font-bold '>Your Acount</h3>
      <p className='text-sm text-gray-500 mt-1 italic'>
        View your account information, change your password, or update your
        billing information.
      </p>
      <AccountInformationForm />
    </div>
  );
};

export default AccountInformation;
