import OrdersButton from "components/orders/OrdersButton";
import OrdersListing from "components/orders/OrdersListing";
import { useState } from "react";

const Orders = () => {
  const [activeTab, setActiveTab] = useState("Completed");
  return (
    <div className='flex-grow h-full min-h-screen'>
      <div className='h-full min-h-screen pl-5 pr-10 py-2 bg-mms-gray-light'>
          <OrdersButton activeTab={activeTab} setActiveTab={setActiveTab} />
          <OrdersListing />
        </div>
      </div>
  );
};

export default Orders;
