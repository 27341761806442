import { chat_profile } from "assets/images";

interface PropsType {
  type?: string;
}
const ChatProfilePicture: React.FC<PropsType> = ({ type }) => {
  return (
    <div className='relative col-span-1 w-8 h-8 rounded-full'>
      <img
        src={chat_profile}
        className='w-full h-full object-cover'
        alt='profile icon'
      />
      {!(type === "sender") && (
        <div className='absolute bottom-0 right-0 w-2 h-2 bg-mms-green-medium rounded-full'></div>
      )}
    </div>
  );
};

export default ChatProfilePicture;
