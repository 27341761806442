import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import EditProductForm from "components/EditProductForm";
import { useNavigate } from "react-router-dom";

const EditProduct: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className='flex-grow h-full min-h-screen'>
      <div className='h-full min-h-screen pl-5 pr-10 py-2 bg-mms-gray-light'>
        <div className='mt-5'>
          <div
            className='flex items-center gap-2 text-sm cursor-pointer'
            onClick={() => navigate("/products")}
          >
            <ArrowLeftIcon className='size-5' />
            <span>Back</span>
          </div>
          <EditProductForm />
        </div>
      </div>
    </div>
  );
};

export default EditProduct;
