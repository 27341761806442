import { PreUser, User } from "__generated__/graphql";
import { createContext, useContext } from "react";

interface Types {
  user: User | PreUser | null;
  setUser: React.Dispatch<React.SetStateAction<User | PreUser | null>>;
}

export const UserContext = createContext<Types | undefined>(undefined);

export const useUserContext = (): Types => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useApiContext must be used within a ApiContext.Provider");
  }
  return context;
};
