import { BuildingStorefrontIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { product_image } from "assets/images";
import { motion, useAnimationControls } from "framer-motion";
import React, { useEffect } from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import Button from "./Button";
import ColorAndSizeBox from "./ColorAndSizeBox";
import { useNavigate } from "react-router-dom";

interface PropsType {
  showProductDetails: boolean;
  setShowProductDetails: (closeSideBar: boolean) => void;
}
const containerVariants = {
  close: {
    width: "0%",
    transition: {
      type: "spring",
      damping: 15,
      duration: 0.5,
    },
  },
  open: {
    width: "33.33%",
    transition: {
      type: "spring",
      damping: 15,
      duration: 0.5,
    },
  },
};

const ProductDetailsSideBar: React.FC<PropsType> = ({
  showProductDetails,
  setShowProductDetails,
}) => {
  const containerControls = useAnimationControls();
  const navigate = useNavigate();
  useEffect(() => {
    if (showProductDetails) {
      containerControls.start("open");
    } else {
      containerControls.start("close");
    }
  }, [showProductDetails, containerControls]);

  return (
    <motion.nav
      variants={containerVariants}
      animate={containerControls}
      initial='close'
      className={`z-10 fixed grid flex-col w-1/3 top-2 bottom-2 ${
        showProductDetails ? "right-2" : "right-[-10px]"
      } pr-2 bg-white rounded-lg border`}
    >
      <div className='grid grid-cols-12 border-b p-3 items-center'>
        <p className='col-span-11 text-center text-sm'>Product Information</p>
        <div>
          <XMarkIcon
            className='size-5 col-span-1 cursor-pointer'
            onClick={() => setShowProductDetails(false)}
          />
        </div>
      </div>
      <div className='w-full flex-grow overflow-y-scroll custom-scrollbar'>
        <div className='grid grid-cols-5 gap-2 mx-auto p-5'>
          <div className='col-span-4 row-span-4 cursor-zoom-in'>
            <Zoom>
              <img
                src={product_image}
                className='w-full h-full object-cover'
                alt='product_image'
              />
            </Zoom>
          </div>
          <div className='cursor-pointer'>
            <img
              src={product_image}
              className='object-contain rounded-lg'
              alt='product_image'
            />
          </div>
          <div className='cursor-pointer'>
            <img
              src={product_image}
              className='object-contain rounded-lg'
              alt='product_image'
            />
          </div>
          <div className='cursor-pointer'>
            <img
              src={product_image}
              className='object-contain rounded-lg'
              alt='product_image'
            />
          </div>
          <div className='cursor-pointer'>
            <img
              src={product_image}
              className='object-contain rounded-lg'
              alt='product_image'
            />
          </div>
        </div>
        <div className='mx-5 p-3 flex flex-col gap-3 border-[0.5px] border-mms-green-Ash rounded-lg'>
          <h4 className='font-semibold text-lg text-mms-green-dark'>
            Long Sleeve Overshirt, Khaki, 6
          </h4>
          <div className='flex gap-2 items-center'>
            <p className='font-semibold text-lg text-mms-orange-medium'>$300</p>
            <span className='text-sm text-mms-green-semi-light underline'>
              In Stock
            </span>
          </div>
          <div className='flex gap-2 items-center text-sm'>
            <span>Color Available:</span>
            <div className='flex gap-2'>
              <ColorAndSizeBox
                type='color'
                color='Brown'
                colorCode='brown'
                handleClick={() => {}}
              />
              <ColorAndSizeBox
                type='color'
                color='Red'
                colorCode='red'
                handleClick={() => {}}
              />
              <ColorAndSizeBox
                type='color'
                color='Green'
                colorCode='green'
                handleClick={() => {}}
              />
            </div>
          </div>
          <div className='flex gap-2 items-center text-sm'>
            <span>Size Available:</span>
            <span className='flex gap-2'>
              <ColorAndSizeBox type='size' size='XS' handleClick={() => {}} />
              <ColorAndSizeBox type='size' size='S' handleClick={() => {}} />
              <ColorAndSizeBox type='size' size='M' handleClick={() => {}} />
              <ColorAndSizeBox type='size' size='L' handleClick={() => {}} />
              <ColorAndSizeBox type='size' size='XL' handleClick={() => {}} />
            </span>
          </div>
          <div className='flex flex-col gap-2 text-sm'>
            <span>Description:</span>
            <span className='text-xs'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia
              voluptates quas, odio ex debitis blanditiis ipsa quod facilis
              necessitatibus harum id quam dolore vero excepturi ut, officia,
              dicta dolorum cum.
            </span>
          </div>
        </div>
      </div>
      <div className='bg-white py-5 flex items-center justify-around'>
        <Button
          label='Boost Product Post'
          onClick={() => navigate("/products/boost")}
          icon={<BuildingStorefrontIcon className='text-white size-5' />}
          containerStyles='bg-mms-green-medium hover:bg-mms-green-semi-dark active:bg-mms-green-dark disabled:bg-mms-gray-disabled'
          textStyles='text-white text-sm'
        />
        <Button
          label='Edit Product'
          icon={
            <BuildingStorefrontIcon className='text-mms-green-medium size-5' />
          }
          onClick={() => navigate("/products/edit")}
          disabled={false}
          containerStyles='bg-white border border-mms-green-medium hover:bg-gray-100 active:bg-gray-300 disabled:bg-mms-gray-disabled'
          textStyles='text-mms-green-medium text-sm disabled:text-white'
        />
      </div>
    </motion.nav>
  );
};

export default ProductDetailsSideBar;
