const CustomArrow = () => {
  return (
    <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
      <svg
        className='h-5 w-5 fill-current text-mms-green-medium'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 20 20'
      >
        <path
          fillRule='evenodd'
          d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
          clipRule='evenodd'
        />
      </svg>
    </div>
  );
};

export default CustomArrow;
