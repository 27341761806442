import GigsListing from "components/gigs_market/GigsListing";
import GigsMarketBanner from "components/gigs_market/GigsMarketBanner";
import React from "react";

const GigsMarket: React.FC = () => {
  return (
    <div className='flex-grow h-full min-h-screen'>
      <div className='h-full min-h-screen pl-5 pr-10 py-2 bg-mms-gray-light'>
          <GigsMarketBanner />
          <GigsListing />
        </div>
      </div>
  );
};

export default GigsMarket;
