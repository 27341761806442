import React from "react";
import { tab_line, tab_line_active } from "../assets/images";

interface TabProps {
  isActive: boolean;
}

interface TabsProps {
  activeTab: string;
}

const Tab: React.FC<TabProps> = ({ isActive }) => {
  return (
    <button>
      {isActive ? (
        <img src={tab_line_active} className='w-20' alt='tab_line_active' />
      ) : (
        <img src={tab_line} className='w-20' alt='tab_line' />
      )}
    </button>
  );
};

const Tabs: React.FC<TabsProps> = ({ activeTab }) => {
  return (
    <div>
      <div className='my-3 flex justify-center gap-x-5'>
        <Tab isActive={activeTab === "Tab 1"} />
        <Tab isActive={activeTab === "Tab 2"} />
      </div>
    </div>
  );
};

export default Tabs;
