import { inflow, outflow } from "assets/icons";

const WalletTransaction = () => {
  return (
    <div className='mt-10 bg-white p-5 rounded-lg'>
      <div className='mb-2 flex justify-between items-center'>
        <h3 className='font-bold '>Transaction History</h3>
        <div>
          <select className='border p-3 rounded-lg outline-none'>
            <option>Date</option>
          </select>
        </div>
      </div>
      <div className=''>
        <div className='bg-mms-green-medium text-sm font-bold text-white grid grid-cols-12 items-center rounded-md p-2 mb-2'>
          <div className='flex items-center gap-3 col-span-2'>
            <div className='custom-checkbox h-5 w-fit'>
              <input
                type='checkbox'
                className='appearance-none w-5 h-5 text-sm border border-white rounded checked:before:text-white'
              />
            </div>
            <p className='col-span-2'>Transaction ID</p>
          </div>
          <p className='col-span-2'>Date</p>
          <p>Type</p>
          <p className='col-span-2'>Description</p>
          <p className='col-span-2 text-center'>Amount</p>
          <p>Status</p>
          <p className='col-span-2'>Balance After</p>
        </div>

        <div className='text-sm grid grid-cols-12 items-center rounded-lg p-2 mb-2 cursor-pointer hover:bg-gray-100'>
          <div className='flex items-center gap-3 col-span-2'>
            <div className='custom-checkbox h-5 w-fit bg-white m-0'>
              <input
                type='checkbox'
                className='appearance-none w-5 h-5 text-sm border border-black rounded checked:before:text-mms-green-medium'
              />
            </div>
            <p className='col-span-2'>TXN26523</p>
          </div>
          <p className='col-span-2'>15 May 2020 10:00 pm</p>
          <p>
            <img src={inflow} className='size-5'  alt="in flow" />
          </p>
          <p className='col-span-2'>Refund from Order #ORD98765</p>
          <p className='col-span-2 text-center'>N2,000</p>
          <p className='text-mms-green-medium'>Completed</p>
          <p className='col-span-2'>₦200,000</p>
        </div>
        <div className='text-sm grid grid-cols-12 items-center rounded-lg p-2 mb-2'>
          <div className='flex items-center gap-3 col-span-2'>
            <div className='custom-checkbox h-5 w-fit bg-white m-0'>
              <input
                type='checkbox'
                className='appearance-none w-5 h-5 text-sm border border-black rounded checked:before:text-mms-green-medium'
              />
            </div>
            <p className='col-span-2'>TXN26523</p>
          </div>
          <p className='col-span-2'>15 May 2020 10:00 pm</p>
          <p>
            <img src={outflow} className='size-5'  alt="out flow"/>
          </p>
          <p className='col-span-2'>Refund from Order #ORD98765</p>
          <p className='col-span-2 text-center'>N2,000</p>
          <p className='text-mms-orange-medium'>Pending</p>
          <p className='col-span-2'>₦200,000</p>
        </div>
        <div className='text-sm grid grid-cols-12 items-center rounded-lg p-2 mb-2'>
          <div className='flex items-center gap-3 col-span-2'>
            <div className='custom-checkbox h-5 w-fit bg-white m-0'>
              <input
                type='checkbox'
                className='appearance-none w-5 h-5 text-sm border border-black rounded checked:before:text-mms-green-medium'
              />
            </div>
            <p className='col-span-2'>TXN26523</p>
          </div>
          <p className='col-span-2'>15 May 2020 10:00 pm</p>
          <p>
            <img src={inflow} className='size-5'  alt="inflow"/>
          </p>
          <p className='col-span-2'>Refund from Order #ORD98765</p>
          <p className='col-span-2 text-center'>N2,000</p>
          <p className='text-mms-red-medium'>Failed</p>
          <p className='col-span-2'>₦200,000</p>
        </div>
      </div>
    </div>
  );
};

export default WalletTransaction;
