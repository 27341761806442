import TodaySales from "components/TodaySales";
import ExportData from "./ExportData";

const DailySalesReport = () => {
  return (
    <div>
      <ExportData />
      <div className='grid grid-cols-12 gap-5 mt-5'>
        <TodaySales />
        <div className='col-span-5 bg-white rounded-lg shadow-sm p-3'>
          <div className='flex items-center gap-5 text-xs'>
            <h3 className='font-bold'>Visitors Insight</h3>
            <div className='flex items-center gap-2'>
              <div className='flex items-center gap-1'>
                <div className='w-3 h-3 bg-mms-green-medium rounded-full'></div>
                <span>Returning clients</span>
              </div>
              <div className='flex items-center gap-1'>
                <div className='w-3 h-3 bg-mms-green-medium rounded-full'></div>
                <span>New clients</span>
              </div>
              <div className='flex items-center gap-1'>
                <div className='w-3 h-3 bg-mms-green-medium rounded-full'></div>
                <span>new clients</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailySalesReport;
