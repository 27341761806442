import MessageChatListDisplay from "components/MessageChatDisplay";
import MessageChatList from "components/MessageChatList";
const Messages = () => {
  return (
    <div className='flex-grow h-full min-h-screen'>
      <div className='h-screen min-h-screen pl-5 pr-10 py-2 bg-mms-gray-light'>
        <div className='flex-grow grid grid-cols-12 mt-10 gap-5'>
          <MessageChatList />
          <MessageChatListDisplay />
        </div>
      </div>
    </div>
  );
};

export default Messages;
