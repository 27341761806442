import AdsButton from "components/ads/AdsButton";
import AdsListing from "components/ads/AdsListing";
import { useState } from "react";

const Ads = () => {
  const [activeTab, setActiveTab] = useState("Ads Overview");
  return (
    <div className='flex-grow h-full min-h-screen'>
      <div className='h-full min-h-screen pl-5 pr-10 py-2 bg-mms-gray-light'>
        <AdsButton activeTab={activeTab} setActiveTab={setActiveTab} />
        <AdsListing activeTab={activeTab} />
      </div>
    </div>
  );
};

export default Ads;
