import { useEffect, useState } from "react";
import { mms_icon, signup_image } from "assets/images";
import RegisterForm from "components/RegisterForm";
import EmailVerificationForm from "components/EmailVerificationForm";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "context/UserContext";

const Register = () => {
  const { user } = useUserContext();
  const navigate = useNavigate();
  useEffect(() => {
    if (user?.__typename === "User") {
      navigate("/dashboard");
    }
  }, [user, navigate]);
  const [activeTab, setActiveTab] = useState<string>("Tab 1");

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };
  return (
    <>
      <div className='grid grid-cols-11 h-full overflow-hidden'>
        <div className='col-span-5 flex flex-col p-8 max-h-screen gap-10 overflow-y-scroll bg-mms-gray-light'>
          <img src={mms_icon} alt='logo' className='w-16' />
          <div className='w-full flex flex-col gap-4 justify-center mx-auto max-w-xs'>
            <div className='text-center'>
              <h3 className='text-2xl font-semibold'>Create An Account</h3>
              <p className='text-center'>
                Fill in your details to get started.
              </p>
            </div>
            {activeTab === "Tab 1" && (
              <EmailVerificationForm
                activeTab={activeTab}
                handleTabClick={handleTabClick}
              />
            )}
            {activeTab === "Tab 2" && (
              <RegisterForm
                activeTab={activeTab}
                handleTabClick={handleTabClick}
              />
            )}
          </div>
        </div>
        <div className='col-span-6'>
          <img
            src={signup_image}
            alt='signup_image'
            className='h-screen w-full object-cover'
          />
        </div>
      </div>
    </>
  );
};

export default Register;
