import ProductButtons from "components/ProductButtons";
import ProductsListing from "components/ProductsListing";
import React from "react";

const Products: React.FC = () => {
  return (
    <div className='flex-grow h-full'>
      <div className='h-full min-h-screen pl-5 pr-10 py-2 bg-mms-gray-light'>
        <ProductButtons />
        <ProductsListing />
      </div>
    </div>
  );
};

export default Products;
