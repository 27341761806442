import { NewCustomer, ProductSold, TotalOrder, TotalSale } from "assets/icons";

const TodaySales = () => {
  return (
    <div className='col-span-7 bg-white rounded-lg shadow-sm py-5 px-4'>
      <div>
        <h3 className='font-bold'>Today's Sales</h3>
        <p className='text-sm text-gray-300 mt-1'>Sales Summary</p>
      </div>
      <div className='mt-5 grid grid-cols-4 gap-4 justify-start'>
        <div className='col-span-1 border p-2 rounded-lg'>
          <img src={TotalSale} className='size-7 w-6' alt='total sale icon' />
          <p className='font-bold text-lg mt-2'>₦5M</p>
          <p className='text-mms-green-medium'>Total Sales</p>
          <p className='text-mms-orange-medium text-xs mt-1'>
            +10 from yesterday
          </p>
        </div>
        <div className='col-span-1 border p-2 rounded-lg'>
          <img src={TotalOrder} className='size-7 w-6' alt='total order icon' />
          <p className='font-bold text-lg mt-2'>600</p>
          <p className='text-mms-green-medium'>Total Sales</p>
          <p className='text-mms-cyan-medium text-xs mt-1'>
            +12% from yesterday
          </p>
        </div>
        <div className='col-span-1 border p-2 rounded-lg'>
          <img
            src={ProductSold}
            className='size-7 w-6'
            alt='product sold icon'
          />
          <p className='font-bold text-lg mt-2'>20</p>
          <p className='text-mms-green-medium'>Product Sold</p>
          <p className='text-mms-orange-medium text-xs mt-1'>
            +20% from yesterday
          </p>
        </div>
        <div className='col-span-1 border p-2 rounded-lg'>
          <img
            src={NewCustomer}
            className='size-7 w-6'
            alt='new customer icon'
          />
          <p className='font-bold text-lg mt-2'>20</p>
          <p className='text-mms-green-medium'>New Customer</p>
          <p className='text-mms-cyan-medium text-xs mt-1'>
            +20% from yesterday
          </p>
        </div>
      </div>
    </div>
  );
};

export default TodaySales;
