import { naira, withdrawal } from "../assets/images";
import Button from "./Button";
interface PropsType {
  setFundWallet: (bool: boolean) => void;
  setWithdrawalFromWallet: (bool: boolean) => void;
}

const WalletAccount: React.FC<PropsType> = ({
  setFundWallet,
  setWithdrawalFromWallet,
}) => {
  return (
    <div className='grid grid-cols-11 gap-5 mt-5'>
      <div
        className={`col-span-5 bg-mms-custom-pattern-dark bg-no-repeat bg-cover bg-right bg-mms-green-medium text-white text-sm py-6 px-5 rounded-lg border border-gray-300`}
      >
        <p className='mb-2'>Account Balance</p>
        <p className='flex items-center gap-2'>
          <img src={naira} className='w-4 h-4'  alt="naira"/>
          <span className='text-4xl font-bold'>200,000</span>
        </p>
      </div>
      <div
        className={`col-span-3 bg-mms-custom-pattern-light bg-mms-green-extra-light bg-no-repeat bg-cover bg-right text-white text-sm pt-6 pb-3 px-5 rounded-lg border border-gray-300`}
      >
        <p className='mb-3 text-mms-green-medium'>Fund Your Wallet</p>
        <Button
          textStyles='text-white'
          iconImage={withdrawal}
          label='Add Money'
          containerStyles='bg-mms-orange-medium hover:bg-mms-orange-semi-dark active:bg-mms-orange-dark disabled:bg-mms-gray-disabled'
          onClick={() => setFundWallet(true)}
        />
      </div>
      <div
        className={`col-span-3 bg-mms-custom-pattern-light bg-mms-green-extra-light bg-no-repeat bg-cover bg-right text-white text-sm pt-6 pb-3 px-5 rounded-lg border border-gray-300`}
      >
        <p className='mb-3 text-mms-green-medium'>Withdrawal From Wallet</p>
        <Button
          textStyles='text-white'
          iconImage={withdrawal}
          label='Withdrawal Funds'
          containerStyles='bg-mms-orange-medium hover:bg-mms-orange-semi-dark active:bg-mms-orange-dark disabled:bg-mms-gray-disabled'
          onClick={() => setWithdrawalFromWallet(true)}
        />
      </div>
    </div>
  );
};

export default WalletAccount;
