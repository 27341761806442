import React from "react";
import { successful_transaction } from "assets/images";

interface PropTypes {
  setIsSuccess: (bool: boolean) => void;
}
const SuccessTransactionPopUp: React.FC<PropTypes> = ({ setIsSuccess }) => {
  return (
    <div className='fixed top-0 bottom-0 right-0 left-0 bg-[#00000055] flex items-center justify-center'>
      <div className='relative w-2/5 flex flex-col items-center gap-5 bg-white rounded-lg'>
        <img
          src={successful_transaction}
          alt='success'
          className='object-contain cursor-pointer'
          onClick={() => {
            setIsSuccess(false);
          }}
        />
      </div>
    </div>
  );
};

export default SuccessTransactionPopUp;
