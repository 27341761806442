import ChangePasswordForm from "../ChangePasswordForm";

const ChangePassword = () => {
  return (
    <div className='col-span-7 min-h-96 bg-white rounded-lg p-5 border-2 border-gray-100'>
      <h3 className='font-bold '>Change Password</h3>
      <p className='text-sm text-gray-500 mt-1 italic'>
        Changing your password will log you out of all your active sessions
        except the one you’re using at this time.
      </p>
      <ChangePasswordForm />
    </div>
  );
};

export default ChangePassword;
