import { ReactNode, useState } from "react";
import { UserContext } from "./UserContext";
import { PreUser, User } from "__generated__/graphql";

const UserContextProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [user, setUser] = useState<User | PreUser | null>(null);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
