import ConversionRate from "components/sales_report/ConversionRate";
import CustomerSatisfaction from "components/sales_report/CustomerSatisfaction";
import DailySalesReport from "components/sales_report/DailySalesReport";
import KPITarget from "components/sales_report/KPITarget";
import TopProductSoldSection from "components/sales_report/TopProductSoldSection";
const SalesReport = () => {
  return (
    <div className='flex-grow h-full min-h-screen'>
      <div className='h-full min-h-screen pl-5 pr-10 py-2 bg-mms-gray-light'>
        <DailySalesReport />
        <TopProductSoldSection />
        <div className='mt-5 grid grid-cols-3 gap-5 h-72'>
          <ConversionRate />
          <CustomerSatisfaction />
          <KPITarget />
        </div>
      </div>
    </div>
  );
};

export default SalesReport;
