import OrdersListingItem from "./OrdersListingItem";

const OrdersListing = () => {
  return (
    <div className='mt-10 bg-white p-5 rounded-lg'>
      <div className='bg-mms-green-medium text-sm font-bold text-white grid grid-cols-9 items-center rounded-md p-2 mb-2'>
        <p className='col-span-2'>Product Information</p>
        <p>Customer</p>
        <p>Date</p>
        <p>Contact</p>
        <p>Total</p>
        <p>Status</p>
        <p>Quantity</p>
        <p>Delivery Method</p>
      </div>
      <OrdersListingItem />
      <OrdersListingItem />
      <OrdersListingItem />
    </div>
  );
};

export default OrdersListing;
