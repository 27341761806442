import GigsListItem from "./GigsListItem";

const PendingGigsList = () => {
  return (
    <div className='flex flex-col gap-3 mt-5'>
      <GigsListItem connectWithBuyer={false} />
      <GigsListItem connectWithBuyer={false} />
    </div>
  );
};

export default PendingGigsList;
