import DashboardEarnings from "components/DashboardEarnings";
import DashboardProductSold from "components/DashboardProductSold";
import DashboardSalesReport from "components/DashboardSalesReport";
const Dashboard = () => {
  return (
    <div className='flex-grow h-full min-h-screen'>
      <div className='h-full min-h-screen pl-5 pr-10 py-2 bg-mms-gray-light'>
        <DashboardSalesReport />
        <DashboardProductSold />
        <DashboardEarnings />
      </div>
    </div>
  );
};

export default Dashboard;
