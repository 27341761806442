import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { User } from "__generated__/graphql";
import { notification, profile } from "assets/images";
import { useUserContext } from "context/UserContext";
import { useState } from "react";
import NavigationDropDown from "./navigation/NavigationDropDown";
import ProfileDropDown from "./navigation/ProfileDropDown";
import Search from "./Search";
import { mms_logo } from "assets/icons";

const GeneralTopSearchWithNotification = () => {
  const { user } = useUserContext();
  const [showProfileDropDown, setShowProfileDropDown] = useState(false);
  const [showNavigationDropDown, setShowNavigationDropDown] = useState(false);

  return (
    <div className='flex justify-between items-center gap-3 bg-white border-b border-gray-100 py-2'>
      <div className='w-[17%] pl-7  py-2'>
        <img src={mms_logo} alt='logo' className='w-24' />
      </div>
      <div className='flex-grow flex items-center justify-between pr-10'>
        <div className='w-[60%]'>
          <Search />
        </div>

        <div className='w-[20%] flex items-center gap-3 place-content-end'>
          <div className='relative'>
            <img
              src={notification}
              className='w-8 cursor-pointer'
              alt='notification icon'
              onClick={() => {
                setShowNavigationDropDown(!showNavigationDropDown);
                setShowProfileDropDown(false);
              }}
            />
            {showNavigationDropDown && !showProfileDropDown && (
              <NavigationDropDown />
            )}
          </div>

          <div
            className='relative flex items-center gap-2 text-sm cursor-pointer'
            onClick={() => {
              setShowProfileDropDown(!showProfileDropDown);
              setShowNavigationDropDown(false);
            }}
          >
            <img src={profile} className='w-8' alt='profile icon' />
            <div>
              <div className='flex items-center gap-1'>
                <p>{(user as User)?.firstname}</p>
                <p>{(user as User)?.lastname}</p>
              </div>
              <p className='text-xs'>Store 1</p>
            </div>
            {showProfileDropDown ? (
              <ChevronUpIcon className='size-4 cursor-pointer' />
            ) : (
              <ChevronDownIcon
                className='size-4 cursor-pointer'
                onClick={() => {
                  setShowProfileDropDown(!showProfileDropDown);
                  setShowNavigationDropDown(false);
                }}
              />
            )}
            {showProfileDropDown && !showNavigationDropDown && (
              <ProfileDropDown />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralTopSearchWithNotification;
