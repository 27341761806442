import {
  ArrowLeftStartOnRectangleIcon,
  Cog6ToothIcon
} from "@heroicons/react/24/outline";
import { profile } from "assets/images";
import Button from "components/Button";
import { NavLink } from "react-router-dom";

const ProfileDropDown = () => {
  return (
    <div className='z-50 min-w-60 w-full absolute top-10 right-0 shadow-custom-heavy bg-mms-gray-light rounded-md border border-white text-mms-green-dark cursor-default'>
      <div className='relative h-14 pt-3 flex flex-col items-center bg-mms-green-medium rounded-t-lg'>
        <p className='font-semibold text-white'>Store 1</p>
        <img
          src={profile}
          className='w-8 absolute -bottom-4'
          alt='profile icon'
        />
      </div>
      <div className='mt-4 flex flex-col items-center'>
        <div className='my-2'>
          <p className='font-medium text-center text-lg'>John Walls</p>
          <p className='text-sm font-light'>johnwalls@gmail.com</p>
        </div>
      </div>
      <div className='p-2 border-b border-gray-200'>
        <NavLink
          to='/settings'
          className='flex gap-2 hover:bg-mms-green-extra-light py-2 cursor-pointer'
        >
          <Cog6ToothIcon className='w-4' />
          <p>Manage your account</p>
        </NavLink>
        <NavLink
          to={"/login"}
          className='flex gap-2 py-2 hover:bg-mms-green-extra-light cursor-pointer'
        >
          <ArrowLeftStartOnRectangleIcon className='w-4' />
          <p>Sign out</p>
        </NavLink>
      </div>
      <div className='p-2 border-b border-gray-200'>
        <p className='font-medium'>Other stores</p>
        <ul className='mt-2 space-y-2'>
          <li className='flex items-center gap-2'>
            <img
              src={profile}
              alt='profile'
              className='w-4 object-contain rounded-full'
            />
            Store 1
          </li>
          <li className='flex items-center gap-2'>
            <img
              src={profile}
              alt='profile'
              className='w-4 object-contain rounded-full'
            />
            Store 2
          </li>
          <li className='flex items-center gap-2'>
            <img
              src={profile}
              alt='profile'
              className='w-4 object-contain rounded-full'
            />
            Store 3
          </li>
          <li className='flex items-center gap-2'>
            <img
              src={profile}
              alt='profile'
              className='w-4 object-contain rounded-full'
            />
            Store 4
          </li>
        </ul>
      </div>
      <Button
        label='Add store'
        containerStyles='w-full rounded-t-none bg-mms-green-medium hover:bg-mms-green-semi-dark active:bg-mms-green-dark disabled:bg-mms-gray-disabled'
        textStyles='text-white text-sm'
      />
    </div>
  );
};

export default ProfileDropDown;
