import React, { FC, PropsWithChildren } from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  split,
  concat,
  createHttpLink,
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { setContext } from "@apollo/client/link/context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { SERVER_URL, WS_SERVER_URL } from "../config";
import { QueryClient, QueryClientProvider } from "react-query";

const AppContext = React.createContext({});

export const SESSION_TOKEN = "sessionToken";

const httpLink = createHttpLink({
  uri: SERVER_URL + "/graphql",
  headers: {
    "apollo-require-preflight": "true",
  },
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: WS_SERVER_URL + `/graphql`,
    connectionParams: async () => {
      return { Authorization: localStorage.getItem(SESSION_TOKEN) };
    },
  })
);

const authLink = setContext(async (_, { headers }) => {
  const token = localStorage.getItem(SESSION_TOKEN);
  const csrfToken = document.cookie
    .split("; ")
    .find((row) => row.startsWith("csrfToken="))
    ?.split("=")[1];

  return {
    ...headers,
    headers: {
      authorization: token ? `Bearer ${token}` : null,
      "X-CSRF-Token": csrfToken || "",
    },
  };
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const apolloClient = new ApolloClient({
  link: concat(authLink, splitLink),
  cache: new InMemoryCache(),
});

const queryClient = new QueryClient();

export const AppContextProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
    </QueryClientProvider>
  );
};

export default AppContext;
