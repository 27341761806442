import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { Field } from "formik";
import { forwardRef, InputHTMLAttributes, useState } from "react";
interface PropsType extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  name: string;
  label: string;
  placeholder: string;
  labelStyle: string;
  type?: string;
}
const FormField = forwardRef<HTMLInputElement, PropsType>(
  ({ id, name, label, placeholder, labelStyle, type, ...props }, ref) => {
    const [showPassword, setShowPassword] = useState(false);
    return (
      <div className='flex flex-col gap-1 w-full'>
        <label htmlFor={id} className={`text-sm ${labelStyle}`}>
          {label}
        </label>
        <div className='flex items-center border border-gray-300 rounded-lg w-full bg-white'>
          <Field
            ref={ref}
            id={id}
            name={name}
            type={type === "password" ? (showPassword ? "text" : type) : type}
            placeholder={placeholder}
            className='outline-none p-3 rounded-lg w-full bg-transparent'
            {...props}
          />
          {type === "password" && (
            <span
              className='w-[16px] mr-2 cursor-pointer'
              onClick={() => setShowPassword(!showPassword)}
            >
              {!showPassword ? <EyeSlashIcon /> : <EyeIcon />}
            </span>
          )}
        </div>
      </div>
    );
  }
);

export default FormField;
