import ActiveAds from "./ActiveAds";
import ExpiredAds from "./ExpiredAds";
import NoAds from "./NoAds";
import PausedAds from "./PausedAds";

interface PropsType {
  activeTab: string;
}
const AdsListing: React.FC<PropsType> = ({ activeTab }) => {
  return (
    <>
      {activeTab === "Ads Overview" && <NoAds />}
      {activeTab === "Active Ads" && <ActiveAds />}
      {activeTab === "Expired Ads" && <ExpiredAds />}
      {activeTab === "Paused Ads" && <PausedAds />}
    </>
  );
};

export default AdsListing;
