import { InformationCircleIcon } from "@heroicons/react/24/solid";
import { plus } from "assets/images";
import Button from "components/Button";
import FormField from "components/FormField";
import PopupOTP from "components/PopupOTP";
import { ErrorMessage, Form, Formik } from "formik";
import React, { useState } from "react";
import { toast } from "sonner";
import * as Yup from "yup";

const initialValues: any = {
  product_name: "",
  brand: "",
  unit_price: "",
  minimum_order_quantity: "",
  category: "",
  product_description: "",
};
const validationSchema = Yup.object({
  product_name: Yup.string().required("Required"),
  brand: Yup.string().required("Required"),
  unit_price: Yup.number().required("Required"),
  minimum_order_quantity: Yup.number().required("Required"),
  category: Yup.string().required("Required"),
  product_description: Yup.string().required("Required"),
});

const AddNewProductForm: React.FC = () => {
  const [popUp, setPopUp] = useState(false);
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          toast.success("submitted");
          actions.setSubmitting(false);
        }}
      >
        {({ values, errors }) => (
          <div className='mt-5'>
            <h3 className='mb-3 text-xl font-bold'>Product Details</h3>
            <div className='grid grid-cols-2 gap-10'>
              <Form className='col-span-1 max-h-[75vh] pr-10 flex flex-col w-full gap-5 overflow-y-scroll custom-scrollbar'>
                <div className=''>
                  <FormField
                    id='product_name'
                    name='product_name'
                    label='Product Name'
                    labelStyle="mb-1 font-bold relative after:content-['*'] after:text-red-500"
                    placeholder='Product Name'
                    required
                  />
                  <ErrorMessage
                    name='product_name'
                    component='div'
                    className='text-red-500 text-sm'
                  />
                  <div className='text-gray-500 text-sm '>
                    This is a short description of what this textfield does
                  </div>
                </div>
                <div className=''>
                  <FormField
                    id='brand'
                    name='brand'
                    label='Brand'
                    labelStyle="mb-1 font-bold relative after:content-['*'] after:text-red-500"
                    placeholder='Brand'
                    required
                  />
                  <ErrorMessage
                    name='brand'
                    component='div'
                    className='text-red-500 text-sm'
                  />
                  <div className='text-gray-500 text-sm '>
                    This is a short description of what this textfield does
                  </div>
                </div>
                <div className=''>
                  <FormField
                    id='unit_price'
                    name='unit_price'
                    label='Unit Price'
                    type='number'
                    labelStyle="mb-1 font-bold relative after:content-['*'] after:text-red-500"
                    placeholder='Product Name'
                    required
                  />
                  <ErrorMessage
                    name='unit_price'
                    component='div'
                    className='text-red-500 text-sm'
                  />
                  <div className='text-gray-500 text-sm '>
                    This is a short description of what this textfield does
                  </div>
                </div>
                <div className=''>
                  <FormField
                    id='minimum_order_quantity'
                    name='minimum_order_quantity'
                    label='Minimum Order Quantity'
                    type='number'
                    labelStyle="mb-1 font-bold relative after:content-['*'] after:text-red-500"
                    placeholder='Minimum Order Quantity'
                    required
                  />
                  <ErrorMessage
                    name='minimum_order_quantity'
                    component='div'
                    className='text-red-500 text-sm'
                  />
                  <div className='text-gray-500 text-sm '>
                    This is a short description of what this textfield does
                  </div>
                </div>
                <div className=''>
                  <FormField
                    id='category'
                    name='category'
                    label='Category'
                    labelStyle="mb-1 font-bold relative after:content-['*'] after:text-red-500"
                    placeholder='Category'
                    required
                  />
                  <ErrorMessage
                    name='category'
                    component='div'
                    className='text-red-500 text-sm'
                  />
                  <div className='text-gray-500 text-sm '>
                    This is a short description of what this textfield does
                  </div>
                </div>
                <div className=''>
                  <FormField
                    id='product_description'
                    name='product_description'
                    label='Product Description'
                    labelStyle="mb-1 font-bold relative after:content-['*'] after:text-red-500"
                    placeholder='Product Description'
                    required
                  />
                  <ErrorMessage
                    name='product_description'
                    component='div'
                    className='text-red-500 text-sm'
                  />
                  <div className='text-gray-500 text-sm '>
                    This is a short description of what this textfield does
                  </div>
                </div>
                <Button
                  label='Upload Product'
                  loadingText='Creating Account'
                  type='submit'
                  containerStyles='absolute bottom-5 right-10 self-end bg-mms-green-medium hover:bg-mms-green-semi-dark active:bg-mms-green-dark disabled:bg-mms-gray-disabled'
                  textStyles='text-white'
                />
              </Form>
              <div className='col-span-1 grid grid-rows-12 gap-0'>
                <div className='row-span-8 grid grid-span-4 gap-3 items-start'>
                  <div className='col-span-4 flex w-full h-full row-span-12 border border-black border-dashed justify-center items-center cursor-pointer hover:bg-gray-100 '>
                    <img src={plus} alt='plus' className='w-12' />
                  </div>
                  <div className='col-span-1 flex w-full h-24 border border-black border-dashed justify-center items-center cursor-pointer hover:bg-gray-100'>
                    <img src={plus} alt='plus' className='w-6' />
                  </div>
                  <div className='col-span-1 flex w-full h-24 border border-black border-dashed justify-center items-center cursor-pointer hover:bg-gray-100'>
                    <img src={plus} alt='plus' className='w-6' />
                  </div>
                  <div className='col-span-1 flex w-full h-24 border border-black border-dashed justify-center items-center cursor-pointer hover:bg-gray-100'>
                    <img src={plus} alt='plus' className='w-6' />
                  </div>
                  <div className='col-span-1 flex w-full h-24 border border-black border-dashed justify-center items-center cursor-pointer hover:bg-gray-100'>
                    <img src={plus} alt='plus' className='w-6' />
                  </div>
                </div>
                <div className='row-span-4'>
                  <div className='flex items-center gap-1 text-xs'>
                    <InformationCircleIcon className='w-4 text-gray-600' />
                    <p>Upload Product Image</p>
                  </div>
                </div>
              </div>
            </div>
            {popUp && <PopupOTP email={values.email} setPopUp={setPopUp} />}
          </div>
        )}
      </Formik>
    </div>
  );
};

export default AddNewProductForm;
