import { chat_profile, product_image } from "assets/images";
import { useState } from "react";

const OrdersListingItem = () => {
  const [status, setStatus] = useState("Completed");
  return (
    <div className='text-sm grid grid-cols-9 items-center rounded-lg p-2 hover:bg-gray-100 cursor-pointer shadow-gray-300 shadow-custom-light'>
      <p className='col-span-2 flex items-center gap-2'>
        <img
          src={product_image}
          alt='product'
          className='border-[0.5px] border-gray-400 size-10 rounded-lg'
        />
        <div>
          <p className='font-semibold line-clamp-1 pr-3'>
            hermos Water Bottle- Flask hermos Water Bottle- Flask
          </p>
          <p className='font-light text-sm text-gray-500 line-clamp-1'>
            Categories: Fashion
          </p>
        </div>
      </p>
      <div className='flex items-center gap-2'>
        <img src={chat_profile} className='size-5' alt='profile' />
        Seun
      </div>
      <p>25/4/2024</p>
      <p>09023344756</p>
      <p>₦250,000</p>
      <p>
        <select
          style={{
            color:
              status === "Completed"
                ? "#35A162"
                : status === "Pending"
                ? "#FDBF00"
                : "#FF0000",
          }}
          className='border-none outline-none bg-transparent text-sm'
          onChange={(e) => setStatus(e.target.value)}
        >
          <option>Completed</option>
          <option>Pending</option>
          <option>Declined</option>
        </select>
      </p>
      <p>12</p>
      <p>Free delivery</p>
    </div>
  );
};

export default OrdersListingItem;
