import React from "react";
interface PropsType {
  type: string;
  color?: string;
  colorCode?: string;
  size?: string;
  handleClick: () => void;
}
const ColorAndSizeBox: React.FC<PropsType> = ({
  type,
  color,
  colorCode,
  size,
  handleClick,
}) =>
  type === "color" ? (
    <div
      className='flex gap-1 border p-1 rounded-lg text-center'
      onClick={handleClick}
    >
      {color}
      <div
        style={{ backgroundColor: colorCode }}
        className={`w-5 h-5 rounded-md`}
      ></div>
    </div>
  ) : (
    <div
      className='min-w-7 border p-1 rounded-lg text-center'
      onClick={handleClick}
    >
      {size}
    </div>
  );

export default ColorAndSizeBox;
