import { useNavigate } from "react-router-dom";
import Tabs from "./Tabs";
import * as Yup from "yup";
import { ErrorMessage, Form, Formik } from "formik";
import Button from "./Button";
import FormField from "./FormField";
import { useState } from "react";
import { InformationCircleIcon } from "@heroicons/react/24/solid";
import PopupOTP from "./PopupOTP";
import {
  MutationGetEmailOtpArgs,
  PreUser,
  useGetEmailOtpMutation,
} from "__generated__/graphql";
import { useUserContext } from "context/UserContext";
interface PropsType {
  activeTab: string;
  handleTabClick: (text: string) => void;
}

const initialValues: MutationGetEmailOtpArgs = {
  email: "",
};

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Required"),
});

const EmailVerificationForm: React.FC<PropsType> = ({
  activeTab,
  handleTabClick,
}) => {
  const navigate = useNavigate();
  const { user, setUser } = useUserContext();
  const [getOTP, { loading, error }] = useGetEmailOtpMutation();
  const [popUp, setPopUp] = useState(false);
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          setUser({ ...user, email: values.email } as PreUser);
          const response = await getOTP({ variables: { email: values.email } });
          console.log(response);
          setPopUp(true);
        }}
      >
        {({ values, errors }) => (
          <div>
            <Form className='flex flex-col gap-3'>
              <div>
                <FormField
                  type='email'
                  id='email'
                  name='email'
                  label='Email'
                  labelStyle='pl-3'
                  placeholder='Enter email'
                />
                {errors.email ? (
                  <ErrorMessage
                    name='email'
                    component='div'
                    className='text-red-500 text-sm'
                  />
                ) : error ? (
                  <div className='text-red-500 text-sm'>{error?.message}</div>
                ) : (
                  <div className='flex items-center gap-1 text-sm text-gray-500 mt-1'>
                    <InformationCircleIcon className='size-4' />
                    An OTP code will be sent to your email
                  </div>
                )}
              </div>
              <Button
                label='Send OTP'
                loading={loading}
                loadingText='Sending OTP'
                type='submit'
                containerStyles='w-full bg-mms-green-medium mt-3 hover:bg-mms-green-semi-dark active:bg-mms-green-dark disabled:bg-mms-gray-disabled'
                textStyles='text-white'
                onClick={() => {}}
              />

              <Tabs activeTab={activeTab} />
              <div className='text-center text-sm'>
                Do you have an account?{" "}
                <span
                  className='text-mms-blue-medium cursor-pointer'
                  onClick={() => navigate("/login")}
                >
                  Sign in
                </span>
              </div>
            </Form>
            {popUp && (
              <PopupOTP
                email={values.email}
                setPopUp={setPopUp}
                handleTabChange={handleTabClick}
              />
            )}
          </div>
        )}
      </Formik>
    </div>
  );
};

export default EmailVerificationForm;
