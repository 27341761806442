import { XMarkIcon } from "@heroicons/react/24/outline";
import { chat_profile } from "assets/images";

const NotificationListItem = () => {
  return (
    <div className='col-span-11 w-full grid grid-cols-12 gap-5 justify-between mt-[-1px]'>
      <div className='col-span-10 w-full flex gap-5 justify-between border-y border-gray-300 p-3'>
        <div className='flex gap-2'>
          <img src={chat_profile} alt={"chat profile"} className='size-7' />
          <div className=''>
            <h3 className='font-bold'>Shola</h3>
            <p className='font-thin text-gray-700 text-sm'>
              You have a new order! Order #54321 was placed by Tosin Makinde.
              Please confirm and prepare for shipment.
            </p>
            <div className='flex items-center gap-2 font-thin text-gray-700 text-xs'>
              <p>1 min ago</p>
              <div className='w-2 h-2 bg-mms-green-semi-light rounded-full'></div>
              <p>Orders</p>
            </div>
          </div>
        </div>
        <div className='w-2 h-2 bg-mms-blue-light rounded-full'></div>
      </div>
      <XMarkIcon className='col-span-2 w-full size-5 self-center' />
    </div>
  );
};

export default NotificationListItem;
