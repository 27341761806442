import { XMarkIcon } from "@heroicons/react/24/outline";
import { master_card, visa_card } from "assets/images";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "sonner";
import { useState } from "react";

interface PropsType {
  setPopUp: (bool: boolean) => void;
  setIsSuccess: (bool: boolean) => void;
}
const initialValues: any = {
  amount: "",
};

const validationSchema = Yup.object({
  amount: Yup.number().required("Required"),
});

const FundAccountPopup: React.FC<PropsType> = ({ setPopUp, setIsSuccess }) => {
  const [paymentMethod, setPaymentMethod] = useState<
    "card" | "transfer" | null
  >(null);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        if (paymentMethod === "card") {
          toast.success("payment with card.");
          // setIsSuccess(true);
          setPopUp(false);
        } else if (paymentMethod === "transfer") {
          toast.success("payment with transfer.");
          // setIsSuccess(true);
          setPopUp(false);
        } else {
          toast.success("No payment method selected.");
        }
        // setIsSuccess(true);
        // setPopUp(false);
      }}
    >
      {({ errors }) => (
        <Form className='fixed top-0 bottom-0 right-0 left-0 bg-[#00000055] flex items-center justify-center'>
          <div className='relative w-2/5 flex flex-col items-center gap-5 bg-white rounded-lg p-10'>
            <button
              className='absolute top-2 right-2 p-1 bg-mms-green-medium border-[0.5px] border-gray-500 rounded-full hover:bg-mms-green-semi-dark active:bg-mms-green-dark disabled:bg-mms-gray-disabled'
              onClick={() => setPopUp(false)}
            >
              <XMarkIcon className='size-4 text-white' />
            </button>
            <div
              className={`w-full col-span-5 bg-mms-custom-pattern-otp bg-no-repeat bg-cover bg-right bg-mms-green-medium text-white text-sm py-5 px-6 rounded-md text-center`}
            >
              <h3 className='font-bold text-2xl'>Fund Account</h3>
              <p className='text-xs'>How much do you want to top up</p>
            </div>
            <div className='w-full flex flex-col'>
              <div className='flex flex-col gap-1 w-full'>
                <label htmlFor='amount' className='text-sm'>
                  Amount
                </label>
                <div className='flex items-center border border-gray-300 rounded-lg w-full bg-white'>
                  <Field
                    type='number'
                    id='amount'
                    name='amount'
                    className='outline-none p-3 rounded-lg w-full bg-transparent'
                    placeholder='N 20,000'
                  />
                </div>
              </div>
              {errors.amount && (
                <ErrorMessage
                  name='amount'
                  component='div'
                  className='text-red-500 text-sm'
                />
              )}
              <button
                className='flex items-center gap-5 border-b text-sm p-3 cursor-pointer hover:bg-gray-100 mt-5'
                onClick={() => setPaymentMethod("card")}
              >
                <span>Pay with Card</span>
                <img
                  src={visa_card}
                  className='size-7 object-contain'
                  alt='visa card'
                />
                <img
                  src={master_card}
                  className='size-7 object-contain'
                  alt='master card'
                />
              </button>
              <button
                className='flex items-center gap-5 border-b text-sm p-3 cursor-pointer hover:bg-gray-100'
                onClick={() => setPaymentMethod("transfer")}
              >
                <span>Pay with Bank Transfer</span>
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FundAccountPopup;
