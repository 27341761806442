import {
  ArrowLeftIcon,
  EllipsisVerticalIcon,
  FaceSmileIcon,
} from "@heroicons/react/24/outline";
import Button from "components/Button";
import ChatProfilePicture from "components/ChatProfilePicture";
import MessageChatDisplayRecepient from "components/MessageChatDisplayRecepient";
import MessageChatDisplaySender from "components/MessageChatDisplaySender";
import MessageTextArea from "components/MessageTextArea";
import { useNavigate } from "react-router-dom";

const SupportChat: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className='flex-grow h-full min-h-screen'>
      <div className='h-full min-h-screen pl-5 pr-10 py-2 bg-mms-gray-light'>
        <div className='mt-5'>
          <div
            className='flex items-center gap-2 text-sm cursor-pointer'
            onClick={() => navigate("/support")}
          >
            <ArrowLeftIcon className='size-5' />
            <span>Back</span>
          </div>
        </div>
        <div className='mt-5 flex-grow border-[0.5px] border-gray-300 bg-white rounded-md flex flex-col'>
          <div className='flex items-center justify-between p-3 border-b-[0.5px] border-gray-300'>
            <div className='flex items-center'>
              <ChatProfilePicture />
              <div className='col-span-7 ml-2'>
                <p className='font-bold text-sm'>MMS Support</p>
                <p className='font-light text-xs text-mms-green-medium'>
                  Online
                </p>
              </div>
            </div>
          </div>
          <div className='flex-grow flex flex-col justify-between relative'>
            <div className='flex flex-col p-5'>
              <MessageChatDisplayRecepient />
              <MessageChatDisplaySender />
              <MessageChatDisplayRecepient />
              <MessageChatDisplaySender />
            </div>
            <div className='sticky bottom-0 border-[0.5px] border-gray-300 bg-white flex items-center p-3 max overflow-hidden m-5 rounded-lg'>
              <MessageTextArea />
              <FaceSmileIcon className='size-6' />
              <EllipsisVerticalIcon className='size-6' />
              <Button
                label='Send Message'
                loading={false}
                containerStyles='bg-mms-green-medium hover:bg-mms-green-semi-dark active:bg-mms-green-dark disabled:bg-mms-gray-disabled'
                textStyles='text-white text-xs whitespace-nowrap'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportChat;
