import React from "react";
import SettingTab from "./SettingTab";
import {
  CreditCardIcon,
  EyeSlashIcon,
  KeyIcon,
  UserIcon,
} from "@heroicons/react/24/outline";

interface PropsType {
  activeTab: string;
  handleTabClick: (text: string) => void;
}

const SettingsTabsNavigation: React.FC<PropsType> = ({
  activeTab,
  handleTabClick,
}) => {
  return (
    <div className='mt-14 col-span-5 h-fit'>
      <SettingTab
        icon={<UserIcon className='size-5' />}
        defaultStyle='hover:bg-mms-green-extra-light text-sm'
        activeStyle='bg-mms-green-extra-light cursor-not-allowed text-mms-green-dark border-r-2 border-mms-green-medium'
        activeTab={activeTab}
        label={"Account Information"}
        handleTabClick={handleTabClick}
      />

      <SettingTab
        icon={<KeyIcon className='size-5' />}
        defaultStyle='hover:bg-mms-green-extra-light text-sm'
        activeStyle='bg-mms-green-extra-light cursor-not-allowed text-mms-green-dark border-r-2 border-mms-green-medium'
        activeTab={activeTab}
        label={"Change Password"}
        handleTabClick={handleTabClick}
      />
      <SettingTab
        icon={<CreditCardIcon className='size-5' />}
        defaultStyle='hover:bg-mms-green-extra-light text-sm'
        activeStyle='bg-mms-green-extra-light cursor-not-allowed text-mms-green-dark border-r-2 border-mms-green-medium'
        activeTab={activeTab}
        label={"Add Payment Account"}
        handleTabClick={handleTabClick}
      />
      <SettingTab
        icon={<EyeSlashIcon className='size-5' />}
        defaultStyle='hover:bg-mms-green-extra-light text-sm'
        activeStyle='bg-mms-green-extra-light cursor-not-allowed text-mms-green-dark border-r-2 border-mms-green-medium'
        activeTab={activeTab}
        label={"Privacy and Safety"}
        handleTabClick={handleTabClick}
      />
      <SettingTab
        type='delete'
        defaultStyle='hover:bg-mms-red-light text-sm text-mms-red-medium'
        activeStyle='bg-mms-red-light cursor-not-allowed'
        activeTab={activeTab}
        label={"Delete Account"}
        handleTabClick={handleTabClick}
      />
    </div>
  );
};

export default SettingsTabsNavigation;
