import { CreditCardIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import Button from "components/Button";

export interface accountDetailsTypes {
  account_holder_name: string;
  bank_name: string;
  bank_account_number: string;
}
const paymentAccounts = [
  {
    account_holder_name: "Kayode Odeyemi",
    bank_name: "Sterling Bank",
    bank_account_number: "09892495830",
  },
  {
    account_holder_name: "Kayode Olawoore",
    bank_name: "UBA Bank",
    bank_account_number: "09374845384",
  },
];
interface PropsType {
  setAccountDetails: React.Dispatch<React.SetStateAction<accountDetailsTypes>>;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
}
const PaymentAccountList: React.FC<PropsType> = ({
  setActiveTab,
  setAccountDetails,
}) => {
  const handleUpdateClick = (account: accountDetailsTypes) => {
    setActiveTab("update_payment_account");
    setAccountDetails({ ...account });
  };
  return (
    <div className='flex flex-col col-span-7 min-h-96 bg-white rounded-lg p-5 border-2 border-gray-100'>
      <h3 className='font-bold'>Payment Account</h3>
      <p className='text-sm text-gray-500 mt-1 italic'>
        Add a bank account to receive payment. Bank account details must match
        the information used to verify your business account.
      </p>
      <div className='flex flex-col'>
        {paymentAccounts.map((account, index) => (
          <div
            className={`py-3 ${
              index < paymentAccounts.length - 1 && "border-b"
            }`}
          >
            <div className='flex gap-3 justify-between bg-mms-green-light p-3 rounded-lg'>
              <div className='flex gap-4 items-start'>
                <CreditCardIcon className='size-5' />
                <div className='text-mms-green-dark'>
                  <p className='font-bold'>{account.account_holder_name}</p>
                  <p className='text-sm'>{account.bank_name}</p>
                  <p className='text-sm'>{account.bank_account_number}</p>
                </div>
              </div>
              <PencilSquareIcon
                className='size-5 cursor-pointer'
                onClick={() => handleUpdateClick(account)}
              />
            </div>
          </div>
        ))}
      </div>
      <Button
        label='Add new payment method'
        loadingText='Creating Account'
        type='submit'
        onClick={() => setActiveTab("add_payment_account")}
        containerStyles='self-end bg-mms-green-medium hover:bg-mms-green-semi-dark active:bg-mms-green-dark disabled:bg-mms-gray-disabled'
        textStyles='text-white'
      />
    </div>
  );
};

export default PaymentAccountList;
