import { useNavigate } from "react-router-dom";
import Button from "./Button";

const ProductButtons: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className='flex items-center justify-between mt-5'>
      <div className='flex items-center gap-3'>
        <Button
          label='In Stock'
          containerStyles='bg-mms-green-medium hover:bg-mms-green-semi-dark active:bg-mms-green-dark disabled:bg-mms-gray-disabled'
          textStyles='text-white text-sm'
        />
        <Button
          label='Out of Stock'
          containerStyles='bg-mms-green-medium hover:bg-mms-green-semi-dark active:bg-mms-green-dark disabled:bg-mms-gray-disabled'
          textStyles='text-white text-sm'
        />
      </div>
      <Button
        label='Add New Product'
        containerStyles='bg-mms-green-medium hover:bg-mms-green-semi-dark active:bg-mms-green-dark disabled:bg-mms-gray-disabled'
        textStyles='text-white text-sm'
        onClick={() => navigate("/products/add")}
      />
    </div>
  );
};

export default ProductButtons;
