import { ErrorMessage, Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import FormField from "./FormField";

import { useCompleteProfileMutation } from "__generated__/graphql";
import { SESSION_TOKEN } from "context/AppContext";
import { useUserContext } from "context/UserContext";
import { COUNTRY_LABELS } from "helpers";
import ReactFlagsSelect from "react-flags-select";
import { useNavigate } from "react-router-dom";
import Button from "./Button";
import Tabs from "./Tabs";

const initialValues: any = {
  firstname: "",
  lastname: "",
  country: "",
  phone_number: "",
  password: "",
};

const validationSchema = Yup.object({
  firstname: Yup.string().required("Required"),
  lastname: Yup.string().required("Required"),
  country: Yup.string().required("Required"),
  phone_number: Yup.string()
    .matches(/^[0-9]{10,}$/, "Phone number must be exactly 10 digits")
    .required("Required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Required"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Required"),
});

interface PropsType {
  activeTab: string;
  handleTabClick: (text: string) => void;
}
const RegisterForm: React.FC<PropsType> = ({ activeTab, handleTabClick }) => {
  const navigate = useNavigate();
  const { user, setUser } = useUserContext();
  const [completeProfile, { loading, error }] = useCompleteProfileMutation();
  const [country, setCountry] = useState("");

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        console.log(values);
        const response = await completeProfile({
          variables: {
            firstname: values.firstname,
            lastname: values.lastname,
            country: values.country,
            phoneNumber: values.phone_number,
            password: values.password,
          },
        });
        if (
          response.data?.completeProfile.user &&
          response.data?.completeProfile.token
        ) {
          setUser({
            ...user,
            ...response.data?.completeProfile.user,
          });
          localStorage.setItem(
            SESSION_TOKEN,
            response.data?.completeProfile.token
          );
          navigate("/");
        }
        actions.setSubmitting(false);
      }}
    >
      {({ values, errors }) => (
        <Form className='flex flex-col w-full gap-3'>
          <div className='grid grid-cols-2 gap-3'>
            <div className=''>
              <FormField
                id='first_name'
                name='firstname'
                label='First Name'
                labelStyle='pl-3'
                placeholder='First name'
              />
              <ErrorMessage
                name='firstname'
                component='div'
                className='text-red-500 text-sm'
              />
            </div>
            <div>
              <FormField
                id='last_name'
                name='lastname'
                label='Last Name'
                labelStyle='pl-3'
                placeholder='Last name'
              />
              <ErrorMessage
                name='lastname'
                component='div'
                className='text-red-500 text-sm'
              />
            </div>
          </div>
          <div>
            <FormField
              id='phone_number'
              name='phone_number'
              type='tel'
              label='Phone Number'
              labelStyle='pl-3'
              placeholder='Phone number'
            />
            <ErrorMessage
              name='phone_number'
              component='div'
              className='text-red-500 text-sm'
            />
          </div>
          <div className='flex flex-col gap-1'>
            <label htmlFor='country' className='text-sm pl-3'>
              Country
            </label>
            <ReactFlagsSelect
              id='country'
              placeholder='Select your country'
              selected={country}
              countries={Object.keys(COUNTRY_LABELS)}
              customLabels={COUNTRY_LABELS}
              onSelect={(code) => {
                setCountry(code);
                values.country = COUNTRY_LABELS[code];
                errors.country = "";
              }}
            />
            {errors.country && (
              <ErrorMessage
                name='country'
                component='div'
                className='text-red-500 text-sm'
              />
            )}
          </div>
          <div>
            <FormField
              type='password'
              id='password'
              name='password'
              label='Password'
              labelStyle='pl-3'
              placeholder='Password'
            />
            <ErrorMessage
              name='password'
              component='div'
              className='text-red-500 text-sm'
            />
          </div>
          <div>
            <FormField
              type='password'
              id='confirm_password'
              name='confirm_password'
              label='Confirm Password'
              labelStyle='pl-3'
              placeholder='Confirm password'
            />
            <ErrorMessage
              name='confirm_password'
              component='div'
              className='text-red-500 text-sm'
            />
          </div>
          {error && (
            <div className='text-red-500 text-sm'>{error?.message}</div>
          )}
          <Button
            label='Proceed'
            loading={loading}
            loadingText='Creating Account'
            type='submit'
            containerStyles='w-full bg-mms-green-medium hover:bg-mms-green-semi-dark active:bg-mms-green-dark disabled:bg-mms-gray-disabled'
            textStyles='text-white'
          />
          <div className='text-center text-sm'>
            Already have an account?{" "}
            <span
              className='text-mms-blue-medium cursor-pointer'
              onClick={() => navigate("/login")}
            >
              Sign in now
            </span>
          </div>
          <Tabs activeTab={activeTab} />
          <p className='text-center text-xs'>
            By clicking on the button above, you have agreed to the 
            <span className='text-mms-green-medium'>Terms of Service</span> and 
            <span className='text-mms-green-medium'>Privacy Policy</span>
          </p>
        </Form>
      )}
    </Formik>
  );
};

export default RegisterForm;
