import { HeartIcon, PaperAirplaneIcon } from "@heroicons/react/24/solid";
import { HeartIcon as HeartOutline } from "@heroicons/react/24/outline";
import { share } from "assets/icons";
import { chat_profile } from "assets/images";
import StarsRating from "components/StarsRating";
import { useState } from "react";
import { motion } from "framer-motion";
import { toast } from "sonner";
import { XMarkIcon } from "@heroicons/react/24/outline";

const ReviewListItem = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [reply, setReply] = useState("");
  const [replied, setReplied] = useState(false);
  const [favourite, setFavourite] = useState(false);
  const handleReply = () => {
    if (reply !== "") {
      setReplied(true);
    } else {
      toast.error("Enter reply message.");
    }
  };
  return (
    <div
      className='col-span-1 flex flex-col gap-3 border p-2 rounded-lg bg-mms-gray-extra-light text-gray-700'
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className='flex items-center justify-between gap-3'>
        <div className='flex items-center gap-3'>
          <img src={chat_profile} className='size-5' alt='chat profile' />
          <span>Seun Temitope</span>
        </div>
        <div className='flex items-center gap-2'>
          {favourite ? (
            <HeartIcon
              className={`size-4 text-mms-red-medium cursor-pointer`}
              onClick={() => setFavourite(!favourite)}
            />
          ) : (
            <HeartOutline
              className={`size-4 text-mms-red-medium cursor-pointer`}
              onClick={() => setFavourite(!favourite)}
            />
          )}
          {!replied &&
            (isHovered ? (
              <XMarkIcon className='text-mms-green-medium size-4' />
            ) : (
              <img src={share} className='size-4' alt='share' />
            ))}
        </div>
      </div>
      <StarsRating rating={4} size='size-4' />
      <p className='text-sm'>
        I recently bought the ThermoMax 500ml Stainless Steel Thermal Flask, and
        it's fantastic. The sleek, durable stainless steel design is both
        stylish and functional. It keeps drinks hot for up to 12 hours and cold
        for up to 24 hours, which I've tested during hikes and long trips, and
        it performs flawlessly every time.
      </p>
      <span className='text-mms-orange-medium text-xs underline'>
        Date: Jul 22
      </span>
      {replied ? (
        <motion.div
          initial={{ height: 0, opacity: 0 }}
          animate={{
            height: "auto",
            opacity: 1,
          }}
          transition={{ duration: 0.5 }}
          className='w-full flex flex-col gap-2'
        >
          <p>Reply to comment review</p>
          <div className='text-sm bg-mms-green-semi-light p-2 rounded-lg'>
            <p className='line-clamp-1 text-mms-green-dark'>{reply}</p>
            <p className='text-white text-xs'>Replied Date: Jul 22</p>
          </div>
        </motion.div>
      ) : (
        <motion.div
          initial={{ height: 0, opacity: 0 }}
          animate={{
            height: isHovered ? "auto" : 0,
            opacity: isHovered ? 1 : 0,
          }}
          transition={{ duration: 0.5 }}
          className='w-full flex flex-col gap-2'
        >
          <p>Reply to comment review</p>
          <textarea
            className='w-full bg-white p-2 text-sm outline-none border border-gray-300 rounded-lg'
            rows={3}
            value={reply}
            onChange={(e) => setReply(e.target.value)}
            placeholder='Input message'
          ></textarea>
          <PaperAirplaneIcon
            className='size-5 text-mms-green-medium self-end cursor-pointer'
            onClick={handleReply}
          />
        </motion.div>
      )}
    </div>
  );
};

export default ReviewListItem;
