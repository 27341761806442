import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type AuthResponse = {
  __typename?: 'AuthResponse';
  token: Scalars['String']['output'];
  user: User;
};

export type Mutation = {
  __typename?: 'Mutation';
  completeProfile: AuthResponse;
  getEmailOTP: Scalars['Boolean']['output'];
  login: AuthResponse;
  verifyEmailOTP: PreAuthResponse;
};


export type MutationCompleteProfileArgs = {
  country: Scalars['String']['input'];
  firstname: Scalars['String']['input'];
  lastname: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phone_number: Scalars['String']['input'];
};


export type MutationGetEmailOtpArgs = {
  email: Scalars['String']['input'];
};


export type MutationLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationVerifyEmailOtpArgs = {
  email: Scalars['String']['input'];
  otpCode: Scalars['String']['input'];
};

export type PreAuthResponse = {
  __typename?: 'PreAuthResponse';
  token: Scalars['String']['output'];
  user: PreUser;
};

export type PreUser = {
  __typename?: 'PreUser';
  createdAt: Scalars['String']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type Query = {
  __typename?: 'Query';
  checkIfEmailIsAvailable: Scalars['Boolean']['output'];
  currentUser?: Maybe<User>;
};


export type QueryCheckIfEmailIsAvailableArgs = {
  email: Scalars['String']['input'];
};

export type User = {
  __typename?: 'User';
  country: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  firstname: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastname: Scalars['String']['output'];
  phone_code: Scalars['String']['output'];
  phone_number: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type GetEmailOtpMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type GetEmailOtpMutation = { __typename?: 'Mutation', getEmailOTP: boolean };

export type VerifyEmailOtpMutationVariables = Exact<{
  email: Scalars['String']['input'];
  otpCode: Scalars['String']['input'];
}>;


export type VerifyEmailOtpMutation = { __typename?: 'Mutation', verifyEmailOTP: { __typename?: 'PreAuthResponse', token: string, user: { __typename?: 'PreUser', email: string, id: string } } };

export type CompleteProfileMutationVariables = Exact<{
  firstname: Scalars['String']['input'];
  lastname: Scalars['String']['input'];
  country: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type CompleteProfileMutation = { __typename?: 'Mutation', completeProfile: { __typename?: 'AuthResponse', token: string, user: { __typename?: 'User', id: string, phone_number: string, phone_code: string, firstname: string, lastname: string, email: string, country: string, createdAt: string, updatedAt: string, deletedAt?: string | null } } };

export type LoginMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'AuthResponse', token: string, user: { __typename?: 'User', id: string, phone_number: string, phone_code: string, firstname: string, lastname: string, email: string, country: string, updatedAt: string, createdAt: string, deletedAt?: string | null } } };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename?: 'Query', currentUser?: { __typename?: 'User', id: string, phone_number: string, phone_code: string, firstname: string, lastname: string, email: string, country: string, createdAt: string, updatedAt: string, deletedAt?: string | null } | null };


export const GetEmailOtpDocument = gql`
    mutation GetEmailOTP($email: String!) {
  getEmailOTP(email: $email)
}
    `;

/**
 * __useGetEmailOtpMutation__
 *
 * To run a mutation, you first call `useGetEmailOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetEmailOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getEmailOtpMutation, { data, loading, error }] = useGetEmailOtpMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetEmailOtpMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GetEmailOtpMutation, GetEmailOtpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<GetEmailOtpMutation, GetEmailOtpMutationVariables>(GetEmailOtpDocument, options);
      }
export type GetEmailOtpMutationHookResult = ReturnType<typeof useGetEmailOtpMutation>;
export type GetEmailOtpMutationResult = Apollo.MutationResult<GetEmailOtpMutation>;
export type GetEmailOtpMutationOptions = Apollo.BaseMutationOptions<GetEmailOtpMutation, GetEmailOtpMutationVariables>;
export const VerifyEmailOtpDocument = gql`
    mutation VerifyEmailOTP($email: String!, $otpCode: String!) {
  verifyEmailOTP(email: $email, otpCode: $otpCode) {
    token
    user {
      email
      id
    }
  }
}
    `;

/**
 * __useVerifyEmailOtpMutation__
 *
 * To run a mutation, you first call `useVerifyEmailOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailOtpMutation, { data, loading, error }] = useVerifyEmailOtpMutation({
 *   variables: {
 *      email: // value for 'email'
 *      otpCode: // value for 'otpCode'
 *   },
 * });
 */
export function useVerifyEmailOtpMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VerifyEmailOtpMutation, VerifyEmailOtpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<VerifyEmailOtpMutation, VerifyEmailOtpMutationVariables>(VerifyEmailOtpDocument, options);
      }
export type VerifyEmailOtpMutationHookResult = ReturnType<typeof useVerifyEmailOtpMutation>;
export type VerifyEmailOtpMutationResult = Apollo.MutationResult<VerifyEmailOtpMutation>;
export type VerifyEmailOtpMutationOptions = Apollo.BaseMutationOptions<VerifyEmailOtpMutation, VerifyEmailOtpMutationVariables>;
export const CompleteProfileDocument = gql`
    mutation CompleteProfile($firstname: String!, $lastname: String!, $country: String!, $phoneNumber: String!, $password: String!) {
  completeProfile(
    firstname: $firstname
    lastname: $lastname
    country: $country
    phone_number: $phoneNumber
    password: $password
  ) {
    user {
      id
      phone_number
      phone_code
      firstname
      lastname
      email
      country
      createdAt
      updatedAt
      deletedAt
    }
    token
  }
}
    `;

/**
 * __useCompleteProfileMutation__
 *
 * To run a mutation, you first call `useCompleteProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeProfileMutation, { data, loading, error }] = useCompleteProfileMutation({
 *   variables: {
 *      firstname: // value for 'firstname'
 *      lastname: // value for 'lastname'
 *      country: // value for 'country'
 *      phoneNumber: // value for 'phoneNumber'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useCompleteProfileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompleteProfileMutation, CompleteProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CompleteProfileMutation, CompleteProfileMutationVariables>(CompleteProfileDocument, options);
      }
export type CompleteProfileMutationHookResult = ReturnType<typeof useCompleteProfileMutation>;
export type CompleteProfileMutationResult = Apollo.MutationResult<CompleteProfileMutation>;
export type CompleteProfileMutationOptions = Apollo.BaseMutationOptions<CompleteProfileMutation, CompleteProfileMutationVariables>;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    token
    user {
      id
      phone_number
      phone_code
      firstname
      lastname
      email
      country
      updatedAt
      createdAt
      deletedAt
    }
  }
}
    `;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const CurrentUserDocument = gql`
    query CurrentUser {
  currentUser {
    id
    phone_number
    phone_code
    firstname
    lastname
    email
    country
    createdAt
    updatedAt
    deletedAt
  }
}
    `;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
      }
export function useCurrentUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export function useCurrentUserSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserSuspenseQueryHookResult = ReturnType<typeof useCurrentUserSuspenseQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;