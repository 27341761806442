const PrivacyAndSafety = () => {
  return (
    <div className='col-span-7 max-h-[30rem] bg-white rounded-lg p-5 border-2 border-gray-100 overflow-auto'>
      <h3 className='font-bold'>Privacy & Safety</h3>
      <p className='text-sm text-gray-500 mt-3 italic'>
        Changing your password will log you out of all your active sessions
        except the one you’re using at this time.
      </p>
      <p className='mt-3 text-sm'>
        Office ipsum you must be muted. Expectations buy-in long crack fastworks
        moments. Build pups first-order shoot hits own window. Down about issues
        company ipsum. 2 air team per launch algorithm ocean nor. Ipsum
        contribution hours most an closing streamline or long reinvent. Scraps
        viral baseline take both site call meaningful identify our.
        Technologically member highlights talk options intersection your plane
        one. Wheel mindfulness plan pretend loop. Heads-up if hour you reach
        chime. Able cadence closing resources donuts then high eod do. Working
        seat unpack look company interim club. Cta manager live give harvest
        too. Ladder pups points for pole. Manager ladder items respectively
        client brainstorming per criticality. Both enable watches after ask vec
        picture 4-blocker. Elephant adoption shift giant shoot while dogpile
        backwards drawing-board half. Engagement waste digital fruit eco-system
        quarter market land day. About old bed unlock policy ladder including
        including. Synergy hits ensure happenings impact want. Moving alpha
        dogpile way gmail closest. Journey effects tent here back-end my meeting
        tomorrow want those. Run cc scraps light race start playing working. Got
        club based start you put pushback field keywords more. Driving tiger
        cadence people calculator when panel well decisions. Boys production
        container your done. Follow live teeth you weaponize land view container
        player-coach. Crank should pulling email fit on scope keep keep.
        Requirements cross-pollination then encourage angel market wanted.
        Scraps better hours stakeholders staircase. Cloud metal finance protocol
        offline activities canatics re-inventing. Squad corporate. Run cc scraps
        light race start playing working. Got club based start you put pushback
        field keywords more. Driving tiger cadence people calculator when panel
        well decisions. Boys production container your done. Follow live teeth
        you weaponize land view container player-coach. Crank should pulling
        email fit on scope keep keep. Requirements cross-pollination then
        encourage angel market wanted. Scraps better hours stakeholders
        staircase. Cloud metal finance protocol offline activities canatics
        re-inventing. Squad corporate.
      </p>
    </div>
  );
};

export default PrivacyAndSafety;
