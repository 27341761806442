import { delete_account } from "assets/images";
import Button from "components/Button";

interface PropsType {
  reason: string;
  accountType: string;
  setAccountType: React.Dispatch<React.SetStateAction<string>>;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  setReason: React.Dispatch<React.SetStateAction<string>>;
}
const ConfirmDeletion: React.FC<PropsType> = ({
  accountType,
  setActiveTab,
}) => {
  return (
    <div
      className='col-span-7 h-96 bg-white rounded-lg p-5 border-2
   border-gray-100 flex flex-col items-center justify-center gap-5'
    >
      <h4 className='text-center'>
        Are you sure you want to delete your{" "}
        {accountType === "business" ? "Business" : "Store"} Account? This action
        cannot be undone and will remove all your business information,
        including associated services and data.
      </h4>
      <img
        src={delete_account}
        alt='delete account'
        className='object-contain w-52'
      />
      <div className='flex items-center gap-5'>
        <Button
          label='No'
          containerStyles='bg-mms-green-medium hover:bg-mms-green-semi-dark active:bg-mms-green-dark disabled:bg-mms-gray-disabled place-self-end'
          textStyles='text-white text-sm'
        />
        <Button
          label='Yes'
          onClick={() => setActiveTab("reason_tab")}
          containerStyles='bg-mms-red-medium hover:bg-mms-red-semi-light active:bg-mms-red-dark disabled:bg-mms-red-light place-self-end'
          textStyles='text-white text-sm'
        />
      </div>
    </div>
  );
};

export default ConfirmDeletion;
