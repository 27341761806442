import React from "react";

const ConversionRate = () => {
  return (
    <div className='col-span-1 bg-white rounded-lg shadow-sm p-2'>
      <div className='flex items-center gap-5 text-xs'>
        <h3 className='font-bold'>Conversion Rate</h3>
        <div className='flex items-center gap-2'>
          <div className='flex items-center gap-1'>
            <div className='w-3 h-3 bg-purple-600 rounded-full'></div>
            <span>Order Request</span>
          </div>
          <div className='flex items-center gap-1'>
            <div className='w-3 h-3 bg-mms-pink-medium rounded-full'></div>
            <span>Order Fulfilled</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConversionRate;
