import Button from "components/Button";
import { motion } from "framer-motion";
import { useState } from "react";

interface PropsType {
  reason: string;
  accountType: string;
  setAccountType: React.Dispatch<React.SetStateAction<string>>;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  setReason: React.Dispatch<React.SetStateAction<string>>;
}
const Reasons = [
  "I’m closing my business.",
  "I’m switching to a different service.",
  "I’m concerned about data privacy.",
  "I find the service too expensive.",
  "Other (please specify).",
];
const ReasonTab: React.FC<PropsType> = ({ reason, setReason, accountType }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isOtherReasonHovered, setIsOtherReasonHovered] = useState(false);

  const handleReasonClick = (reason: string, index: number) => {
    if (index !== Reasons.length - 1) {
      setReason(reason);
      setIsHovered(false);
      setIsOtherReasonHovered(false);
    }
  };
  return (
    <div className='flex flex-col gap-5 col-span-7 min-h-96 bg-white rounded-lg p-5 border-2 border-gray-100'>
      <h4 className='font-bold mb-3'>
        Please tell us why you’re deleting your{" "}
        {accountType === "business" ? "Business" : "Store"} Account
      </h4>
      <div className='bg-mms-red-light text-gray-500 p-3 rounded-lg text-sm'>
        To protect our users from potential scams, we will review and
        investigate your request before the account is permanently deleted. This
        process helps ensure the security of our platform.
      </div>
      <div className='flex flex-col gap-1 w-full text-sm'>
        <label htmlFor='reason' className={`text-sm font-bold`}>
          Option*
        </label>
        <div>
          <div
            className='flex items-center justify-between gap-5 border border-gray-300 rounded-lg w-full bg-white py-3 px-5 cursor-pointer'
            onMouseEnter={() => setIsHovered(true)}
          >
            <p>{reason || "Select Option"}</p>
            <div
              className={`w-4 h-4 flex items-center justify-center rounded-full border ${
                reason !== "" ? "border-mms-green-medium" : "border-gray-300"
              }`}
            >
              {reason !== "" && (
                <div className='bg-mms-green-medium w-3 h-3 rounded-full'></div>
              )}
            </div>
          </div>
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{
              height: isHovered ? "auto" : 0,
              opacity: isHovered ? 1 : 0,
            }}
            transition={{ duration: 0.5 }}
            className='w-full flex flex-col gap-2'
          >
            <div className='mt-3 border border-gray-300 rounded-lg overflow-hidden'>
              {Reasons.map((reason, index) => (
                <div
                  className={`flex items-center justify-between gap-5 ${
                    index === Reasons.length - 1
                      ? ""
                      : "border-b border-gray-300"
                  } w-full bg-white p-2 cursor-pointer hover:bg-mms-red-light`}
                  onClick={() => {
                    handleReasonClick(reason, index);
                  }}
                  onMouseEnter={() => {
                    if (index === Reasons.length - 1) {
                      setIsOtherReasonHovered(true);
                    }
                  }}
                >
                  <p>{reason}</p>
                </div>
              ))}
            </div>
          </motion.div>
          {isOtherReasonHovered && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{
                height: "auto",
                opacity: 1,
              }}
              transition={{ duration: 0.5 }}
              className='w-full flex flex-col gap-2'
            >
              <textarea
                className='mt-3 p-2 rounded-lg outline-none border border-gray-300'
                rows={3}
                placeholder='Please specify'
                onChange={(e) => {
                  setIsHovered(false);
                  setReason(e.target.value);
                }}
                value={reason}
              ></textarea>
            </motion.div>
          )}
          {reason !== "" && !isHovered && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{
                height: "auto",
                opacity: 1,
              }}
              transition={{ duration: 0.5 }}
              className='w-full flex flex-col gap-2'
            >
              <Button
                label='Submit deletion request'
                containerStyles='mt-3 w-fit self-end bg-mms-green-medium hover:bg-mms-green-semi-dark active:bg-mms-green-dark disabled:bg-mms-gray-disabled'
                textStyles='text-white text-sm'
              />
            </motion.div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReasonTab;
