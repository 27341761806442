import { product_image } from "assets/images";
import React, { useState } from "react";

interface PropsType {
  initialAction: string;
}
const AdsListingItem: React.FC<PropsType> = ({ initialAction }) => {
  const [action, setAction] = useState(initialAction);
  return (
    <div className='text-sm grid grid-cols-7 items-center rounded-lg p-2 hover:bg-gray-100 cursor-pointer shadow-gray-300 shadow-custom-light'>
      <p className='col-span-2 flex items-center gap-2'>
        <img
          src={product_image}
          alt='product'
          className='border-[0.5px] border-gray-400 size-10 rounded-lg'
        />
        <div>
          <p className='font-semibold line-clamp-1 pr-3'>
            hermos Water Bottle- Flask hermos Water Bottle- Flask
          </p>
          <p className='font-light text-sm text-gray-500 line-clamp-1'>
            Categories: Fashion
          </p>
        </div>
      </p>
      <p>
        <div
          style={{
            backgroundColor:
              action === "Cancelled" || action === "Expired"
                ? "#FF5C53"
                : action === "Paused"
                ? "#FDCC9D"
                : "#C0E2CE",
            color:
              action === "Cancelled" || action === "Expired" ? "#fff" : "#000",
          }}
          className='w-fit px-3 py-1 rounded-full bg-mms-green-light text-mms-green-dark text-center'
        >
          {action === "Continue" ? "Active" : action ? action : "Active"}
        </div>
      </p>
      <p>30 days</p>
      <p>12th Sept, 2024</p>
      <p>₦6,000</p>
      <p>
        <select
          style={{
            backgroundColor:
              action === "Cancelled"
                ? "#FF5C53"
                : action === "Paused"
                ? "#FDCC9D"
                : "transparent",
            color: action === "Cancelled" ? "#fff" : "#000",
          }}
          defaultValue={action === "Expired" ? "" : action ? action : ""}
          className='w-fit border-none outline-none bg-transparent p-1 rounded-lg text-sm'
          onChange={(e) => setAction(e.target.value)}
        >
          <option>Continue</option>
          <option>Cancelled</option>
          <option>Paused</option>
        </select>
      </p>
    </div>
  );
};

export default AdsListingItem;
