import { SUPPORT_QUESTIONS } from "helpers";
import React from "react";
interface PropsType {
  activeTab: number;
  handleTabClick: (text: number) => void;
}

const SupportQuestionResponseDisplay: React.FC<PropsType> = ({
  activeTab,
  handleTabClick,
}) => {
  return (
    <div className='col-span-6 bg-mms-green-extra-light h-96 rounded-3xl pl-32 pr-10 py-10 border-2 border-gray-100'>
      <h3 className='font-bold '>{SUPPORT_QUESTIONS[activeTab].question}</h3>
      <p className='text-sm text-gray-500 mt-5'>
        {SUPPORT_QUESTIONS[activeTab].response}
      </p>
    </div>
  );
};

export default SupportQuestionResponseDisplay;
