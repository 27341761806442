import Search from "./Search";
import MessageChat from "./MessageChat";

const MessageChatList = () => {
  return (
    <div className='col-span-4'>
      <Search inputStyles='bg-transparent' />
      <div>
        <MessageChat/>
        <MessageChat/>
        <MessageChat/>
      </div>
    </div>
  );
};

export default MessageChatList;
