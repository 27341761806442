import ChatProfilePicture from "./ChatProfilePicture";

const MessageChatDisplayRecepient = () => {
  return (
    <div className='flex items-start gap-2 place-self-start max-w-[48%]'>
      <div className=''>
        <ChatProfilePicture />
      </div>
      <div className=''>
        <p className='w-full p-2 rounded-lg text-gray-600 text-xs bg-mms-green-extra-light'>
          Hello, your order was sent out about 3days ago, can you please confirm
          if you have received yur orders
        </p>
        <p className='text-xs font-light text-gray-400 mt-1'>3:22 pm</p>
      </div>
    </div>
  );
};

export default MessageChatDisplayRecepient;
