import { XMarkIcon } from "@heroicons/react/24/outline";
import { chat_profile } from "assets/images";

const NavigationDropDownListItem = () => {
  return (
    <div className='p-3 col-span-12 w-full grid grid-cols-12 gap-5 justify-between mt-[-1px] border-t border-gray-300'>
      <div className='col-span-11 w-full flex gap-5 justify-between'>
        <div className='flex gap-2'>
          <img src={chat_profile} alt={"chat profile"} className='size-5' />
          <div className=''>
            <h3 className='font-bold'>Shola</h3>
            <p className='font-thin text-gray-700'>
              You have a new order! Order #54321 was placed by Tosin Makinde.
              Please confirm and prepare for shipment
            </p>
            <div className='flex items-center gap-2 font-thin text-gray-700'>
              <p>1 min ago</p>
              <div className='w-2 h-2 bg-mms-green-semi-light rounded-full'></div>
              <p>Orders</p>
            </div>
          </div>
        </div>
      </div>
      <div className='col-span-1 flex flex-col gap-7 items-center justify-start'>
        <div className='w-2 h-2 bg-mms-blue-light rounded-full'></div>
        <XMarkIcon className='w-5 self-center' />
      </div>
    </div>
  );
};

export default NavigationDropDownListItem;
