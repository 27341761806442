import CustomArrow from "assets/icons/CustomArrow";
import Button from "../Button";

interface PropsType {
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
}
const OrderButton: React.FC<PropsType> = ({ activeTab, setActiveTab }) => {
  return (
    <div className='flex items-center justify-between mt-5'>
      <div className='flex items-center gap-3'>
        <Button
          isActive={activeTab === "Ads Overview"}
          inActiveStyles='bg-white border border-mms-green-dark'
          inActiveTextStyles='text-mms-green-dark text-sm'
          onClick={() => setActiveTab("Ads Overview")}
          label='Ads Overview'
          containerStyles='bg-mms-green-medium hover:bg-mms-green-semi-dark active:bg-mms-green-dark disabled:bg-mms-gray-disabled'
          textStyles='text-white text-sm'
        />
        <Button
          isActive={activeTab === "Active Ads"}
          inActiveStyles='bg-white border border-mms-green-dark'
          inActiveTextStyles='text-mms-green-dark text-sm'
          onClick={() => setActiveTab("Active Ads")}
          label='Active Ads'
          containerStyles='bg-mms-green-medium hover:bg-mms-green-semi-dark active:bg-mms-green-dark disabled:bg-mms-gray-disabled'
          textStyles='text-white text-sm'
        />
        <Button
          isActive={activeTab === "Paused Ads"}
          inActiveStyles='bg-white border border-mms-green-dark'
          inActiveTextStyles='text-mms-green-dark text-sm'
          onClick={() => setActiveTab("Paused Ads")}
          label='Paused Ads'
          containerStyles='bg-mms-green-medium hover:bg-mms-green-semi-dark active:bg-mms-green-dark disabled:bg-mms-gray-disabled'
          textStyles='text-white text-sm'
        />
        <Button
          isActive={activeTab === "Expired Ads"}
          inActiveStyles='bg-white border border-mms-green-dark'
          inActiveTextStyles='text-mms-green-dark text-sm'
          onClick={() => setActiveTab("Expired Ads")}
          label='Expired Ads'
          containerStyles='bg-mms-green-medium hover:bg-mms-green-semi-dark active:bg-mms-green-dark disabled:bg-mms-gray-disabled'
          textStyles='text-white text-sm'
        />
      </div>
    </div>
  );
};

export default OrderButton;
