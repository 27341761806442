const TopProductSold = () => {
  return (
    <div className='col-span-7 bg-white rounded-lg shadow-sm py-5'>
      <h3 className='font-bold px-4'>Top Products Sold</h3>
      <div className='mt-5 text-xs'>
        <div className='text-sm grid grid-cols-6 items-center px-6 rounded-lg p-2 mb-2 border-b border-gray-100 shadow-gray-200 font-semibold'>
          <p>#</p>
          <p className='col-span-2'>Name</p>
          <p className='col-span-2'>Popularity</p>
          <p>Sales</p>
        </div>
        <div className='grid grid-cols-6 items-center px-6 rounded-lg p-2 mb-2 border-b border-gray-100 shadow-gray-200'>
          <p>01</p>
          <p className='col-span-2'>Home Decore Range</p>
          <p className='col-span-2 pr-5'>
            <input
              type='range'
              defaultValue={80}
              className='range-slider accent-mms-orange-medium'
            />
          </p>
          <p>
            <button className='bg-mms-orange-trans-light py-1 px-3 text-mms-orange-medium rounded-lg border-[0.5px] border-mms-orange-medium'>
              80%
            </button>
          </p>
        </div>
        <div className='grid grid-cols-6 items-center px-6 rounded-lg p-2 mb-2 border-b border-gray-100 shadow-gray-200'>
          <p>02</p>
          <p className='col-span-2'>Disney Princess Dress</p>
          <p className='col-span-2 pr-5'>
            <input
              type='range'
              defaultValue={30}
              className='range-slider accent-mms-cyan-medium'
            />
          </p>
          <p>
            <button className='bg-mms-cyan-light py-1 px-3 text-mms-cyan-medium rounded-lg border-[0.5px] border-mms-cyan-medium'>
              30%
            </button>
          </p>
        </div>
        <div className='grid grid-cols-6 items-center px-6 rounded-lg p-2 mb-2 border-b border-gray-100 shadow-gray-200'>
          <p>05</p>
          <p className='col-span-2'>Bathroom Essentials</p>
          <p className='col-span-2 pr-5'>
            <input
              type='range'
              defaultValue={50}
              className='range-slider accent-mms-pink-medium'
            />
          </p>
          <p>
            <button className='bg-mms-pink-light py-1 px-3 text-mms-pink-medium rounded-lg border-[0.5px] border-mms-pink-medium'>
              50%
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TopProductSold;
