import React from "react";

const KPITarget = () => {
  return (
    <div className='col-span-1 bg-white rounded-lg shadow-sm p-2'>
      <div className='flex items-center justify-around gap-5 text-xs'>
        <h3 className='font-bold'>KPI Targets</h3>
        <div className='flex items-center gap-2'>
          <div className='flex items-center gap-1'>
            <div className='w-3 h-3 bg-mms-green-medium rounded-full'></div>
            <span>Good</span>
          </div>
          <div className='flex items-center gap-1'>
            <div className='w-3 h-3 bg-yellow-500 rounded-full'></div>
            <span>Bad</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KPITarget;
