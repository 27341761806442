import { XMarkIcon } from "@heroicons/react/24/outline";
import { export_icon, success } from "assets/images";
import Button from "components/Button";
import { useEffect, useState } from "react";

const ExportData = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [loadingPercentage, setLoadingPercentage] = useState(0);
  useEffect(() => {
    if (isExporting) {
      const interval = setInterval(() => {
        setLoadingPercentage((prev) => {
          if (prev === 100) {
            setIsExporting(false);
            return prev;
          } else {
            return prev + 20;
          }
        });
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [isExporting, loadingPercentage]);
  const handleTabClose = () => {
    setIsOpen(false);
    setLoadingPercentage(0);
  };
  return (
    <>
      {(isOpen ||
        isExporting ||
        (!isExporting && loadingPercentage === 100)) && (
        <div
          className='z-50 fixed top-0 right-0 left-0 bottom-0 bg-[#00000055]'
          onClick={() => {
            if (isOpen) handleTabClose();
          }}
        ></div>
      )}

      <div className='relative mt-5 flex justify-end'>
        <Button
          label='Export Data'
          containerStyles='bg-mms-green-medium hover:bg-mms-green-semi-dark active:bg-mms-green-dark disabled:bg-mms-gray-disabled'
          iconImage={export_icon}
          onClick={() => setIsOpen(true)}
          textStyles='text-white text-sm'
        />
        {isOpen && (
          <div className='z-50 min-w-60 absolute top-10 right-0 shadow-custom-heavy bg-mms-gray-light rounded-md border border-white p-2 cursor-pointer'>
            <div className='flex justify-end mb-3'>
              <XMarkIcon
                className='w-5 cursor-pointer'
                onClick={() => setIsOpen(false)}
              />
            </div>

            <div className='flex items-center justify-between py-2 px-1 border-b border-gray-200'>
              <p>Convertion Rate</p>
              <input type={"checkbox"} className='accent-mms-green-medium' />
            </div>
            <div className='flex items-center justify-between py-2 px-1 border-b border-gray-200'>
              <p>Visitors Insight</p>
              <input type={"checkbox"} className='accent-mms-green-medium' />
            </div>
            <div className='flex items-center justify-between py-2 px-1 border-b border-gray-200'>
              <p>Top product</p>
              <input type={"checkbox"} className='accent-mms-green-medium' />
            </div>
            <div className='flex items-center justify-between py-2 px-1 border-b border-gray-200'>
              <p>Customers Satisfaction</p>
              <input type={"checkbox"} className='accent-mms-green-medium' />
            </div>
            <div className='mt-3 flex items-center justify-center'>
              <Button
                label='Export Data'
                containerStyles='bg-mms-green-medium hover:bg-mms-green-semi-dark active:bg-mms-green-dark disabled:bg-mms-gray-disabled'
                iconImage={export_icon}
                onClick={() => {
                  setLoadingPercentage(0);
                  setIsExporting(true);
                  setIsOpen(false);
                }}
                textStyles='text-white text-sm'
              />
            </div>
          </div>
        )}
      </div>
      {isExporting && (
        <div className='z-50 absolute left-0 right-0 flex items-center justify-center w-full h-[25rem]'>
          <div className='min-w-60 mx-auto shadow-custom-heavy bg-mms-gray-light rounded-md border border-white p-5'>
            <p className='max-w-[80%] mx-auto font-bold text-center mb-5'>
              Your sales data is now downloading
            </p>
            <div className='w-full h-1 bg-mms-green-semi-light rounded-full'>
              <div
                style={{ width: `${loadingPercentage}%` }}
                className='h-1 bg-mms-green-medium rounded-full'
              ></div>
            </div>
          </div>
        </div>
      )}
      {!isExporting && loadingPercentage === 100 && (
        <div
          className='z-50 absolute left-0 right-0 flex items-center justify-center w-full h-[25rem]'
          onClick={handleTabClose}
        >
          <div className='min-w-60 mx-auto flex flex-col items-center justify-center shadow-custom-heavy bg-mms-gray-light rounded-md border border-white p-5'>
            <img src={success} alt='success icon' className='w-16' />
            <p className='max-w-[80%] mx-auto font-bold text-center mb-5'>
              Your sales report is now in your downloaded files
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default ExportData;
