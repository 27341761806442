import { useUserContext } from "context/UserContext";
import { mms_icon, signup_image } from "../../assets/images";
import LoginForm from "../../components/LoginForm";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const { user } = useUserContext();
  const navigate = useNavigate();
  useEffect(() => {
    if (user?.__typename === "User") {
      navigate("/dashboard");
    }
  }, [user,navigate]);
  return (
    <div className='grid grid-cols-11 h-full'>
      <div className='col-span-5 flex flex-col p-8 max-h-screen gap-10 overflow-y-scroll bg-mms-gray-light'>
        <img src={mms_icon} alt='logo' className='w-16' />
        <div className='flex flex-col gap-4 items-center justify-center mx-auto max-w-xs w-full'>
          <div>
            <h3 className='text-2xl font-semibold'>Welcome Back 👋</h3>
            <p className='text-center'>Sign in to continue</p>
          </div>
          <LoginForm />
          {/* <Tabs activeTab={activeTab} handleTabClick={handleTabClick} /> */}
        </div>
      </div>
      <div className='col-span-6'>
        <img
          src={signup_image}
          alt='signup_image'
          className='h-screen w-full object-cover'
        />
      </div>
    </div>
  );
};

export default Login;
