import DeleteAccount from "components/settings_tab_components/settings_tabs/DeleteAccount";
import PrivacyAndSafety from "components/settings_tab_components/settings_tabs/PrivacyAndSafety";
import SettingsTabsNavigation from "components/settings_tab_components/SettingsTabsNavigation";

import AccountInformation from "components/settings_tab_components/settings_tabs/AccountInformation";
import ChangePassword from "components/settings_tab_components/settings_tabs/ChangePassword";
import PaymentAccount from "components/settings_tab_components/settings_tabs/PaymentAccount";
import { useState } from "react";

const Settings = () => {
  const [settingsActiveTab, setSettingsActiveTab] = useState(
    "Account Information"
  );

  const handleSettingsTabClick = (tab: string) => {
    setSettingsActiveTab(tab);
  };
  return (
    <div className='flex-grow h-full min-h-screen'>
      <div className='h-full min-h-screen pl-5 pr-10 py-2 bg-mms-gray-light'>
        <div className='mt-5 gap-5'>
          <div className='relative flex-grow mt-6 grid grid-cols-12 items-start overflow-scroll'>
            <SettingsTabsNavigation
              activeTab={settingsActiveTab}
              handleTabClick={handleSettingsTabClick}
            />
            {settingsActiveTab === "Account Information" && (
              <AccountInformation />
            )}
            {settingsActiveTab === "Change Password" && <ChangePassword />}
            {settingsActiveTab === "Add Payment Account" && <PaymentAccount />}
            {settingsActiveTab === "Privacy and Safety" && <PrivacyAndSafety />}
            {settingsActiveTab === "Delete Account" && <DeleteAccount />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
