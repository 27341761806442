import { StarIcon } from "@heroicons/react/24/outline";
import { StarIcon as StartSolidIcon } from "@heroicons/react/24/solid";

interface PropsType {
  rating: number;
  setRating?: (n: number) => void;
  size?: string;
}
const StarsRating: React.FC<PropsType> = ({
  rating,
  setRating,
  size = "size-5",
}: PropsType) => {
  const five = [1, 2, 3, 4, 5];

  const handleStarClick = (n: number) => {
    if (setRating) setRating(n);
  };
  return (
    <div className='flex gap-1 items-center cursor-pointer'>
      {five.map((n) => (
        <div onClick={() => handleStarClick(n)}>
          {rating >= n ? (
            <StartSolidIcon className={`${size} text-mms-yellow-medium`} />
          ) : (
            <StarIcon className={`${size} text-mms-yellow-medium`} />
          )}
        </div>
      ))}
    </div>
  );
};

export default StarsRating;
