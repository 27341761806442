import FundAccountPopup from "components/FundWalletPopup";
import SuccessTransactionPopUp from "components/SuccessTransactionPopUp";
import WalletTransaction from "components/WalletTransaction";
import WithdrawalFromWalletPopUp from "components/WithdrawalFromWalletPopUp";
import { useState } from "react";
import WalletAccount from "../components/WalletAccount";

const Wallet = () => {
  const [fundWallet, setFundWallet] = useState(false);
  const [WithdrawalFromWallet, setWithdrawalFromWallet] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  return (
    <div className='flex-grow h-full min-h-screen'>
      <div className='h-full min-h-screen pl-5 pr-10 py-2 bg-mms-gray-light'>
        <WalletAccount
          setFundWallet={setFundWallet}
          setWithdrawalFromWallet={setWithdrawalFromWallet}
        />
        <WalletTransaction />
        {fundWallet && (
          <FundAccountPopup
            setIsSuccess={setIsSuccess}
            setPopUp={setFundWallet}
          />
        )}
        {WithdrawalFromWallet && (
          <WithdrawalFromWalletPopUp
            setIsSuccess={setIsSuccess}
            setPopUp={setWithdrawalFromWallet}
          />
        )}
        {isSuccess && <SuccessTransactionPopUp setIsSuccess={setIsSuccess} />}
      </div>
    </div>
  );
};

export default Wallet;
