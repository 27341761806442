import Button from "components/Button";
import FormField from "components/FormField";
import { ErrorMessage, Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";

const initialValues: any = {
  current_password: "",
  new_password: "",
  confirm_password: "",
};

const validationSchema = Yup.object({
  current_password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Required"),
  new_password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Required"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Required"),
});

const AccountInformationForm: React.FC = () => {
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          console.log(values);
          actions.setSubmitting(false);
        }}
      >
        {({ values }) => (
          <Form className='mt-5 flex flex-col w-full gap-3'>
            <div>
              <FormField
                type='password'
                id='current_password'
                name='current_password'
                label='Current Password'
                labelStyle="mb-1 font-bold relative after:content-['*'] after:text-red-500"
                placeholder='Current Password'
              />
              <ErrorMessage
                name='current_password'
                component='div'
                className='text-red-500 text-sm'
              />
            </div>
            <div>
              <FormField
                type='password'
                id='new_password'
                name='new_password'
                label='New Password'
                labelStyle="mb-1 font-bold relative after:content-['*'] after:text-red-500"
                placeholder='New Password'
              />
              <ErrorMessage
                name='new_password'
                component='div'
                className='text-red-500 text-sm'
              />
            </div>
            <div>
              <FormField
                type='password'
                id='confirm_password'
                name='confirm_password'
                label='Confirm Password'
                labelStyle="mb-1 font-bold relative after:content-['*'] after:text-red-500"
                placeholder='Confirm Password'
              />
              <ErrorMessage
                name='confirm_password'
                component='div'
                className='text-red-500 text-sm'
              />
            </div>

            <Button
              label='Update account'
              loadingText='Creating Account'
              type='submit'
              containerStyles='self-end bg-mms-green-medium hover:bg-mms-green-semi-dark active:bg-mms-green-dark disabled:bg-mms-gray-disabled'
              textStyles='text-white'
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AccountInformationForm;
