import Button from "components/Button";

interface PropsType {
  accountType: string;
  setAccountType: React.Dispatch<React.SetStateAction<string>>;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
}
const AccountType: React.FC<PropsType> = ({
  accountType,
  setAccountType,
  setActiveTab,
}) => {
  return (
    <div className='flex flex-col gap-10 col-span-7 min-h-96 bg-white rounded-lg p-5 border-2 border-gray-100'>
      <div className='text-center'>
        <h4 className='font-bold mb-3'>Delete Account</h4>
        <p>
          Which account would you like to delete? Please choose one of the
          following options. This action is irreversible
        </p>
      </div>
      <div className='flex flex-col gap-3'>
        <div
          className='flex items-center justify-between gap-5 bg-mms-green-extra-light text-mms-green-dark p-3 rounded-lg cursor-pointer'
          onClick={() => setAccountType("business")}
        >
          Business Account
          <div
            className={`w-4 h-4 border rounded-full ${
              accountType === "business"
                ? "bg-mms-green-medium"
                : "border-mms-green-dark"
            }`}
          ></div>
        </div>
        <div
          className='flex items-center justify-between gap-5 bg-mms-green-extra-light text-mms-green-dark p-3 rounded-lg cursor-pointer'
          onClick={() => setAccountType("store")}
        >
          Store
          <div
            className={`w-4 h-4 border rounded-full ${
              accountType === "store"
                ? "bg-mms-green-medium"
                : "border-mms-green-dark"
            }`}
          ></div>
        </div>
      </div>
      {accountType !== "" && (
        <Button
          label='Continue'
          onClick={() => setActiveTab("confirm_tab")}
          containerStyles='w-fit self-center bg-mms-green-medium hover:bg-mms-green-semi-dark active:bg-mms-green-dark disabled:bg-mms-gray-disabled'
          textStyles='text-white text-sm'
        />
      )}
    </div>
  );
};

export default AccountType;
