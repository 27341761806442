import React, { FormEvent, useState } from "react";
import OTPInput from "./OTPInput";
import Button from "./Button";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useVerifyEmailOtpMutation } from "__generated__/graphql";
import { useUserContext } from "context/UserContext";
import { SESSION_TOKEN } from "context/AppContext";
interface PropsType {
  setPopUp: (bool: boolean) => void;
  handleTabChange?: (text: string) => void;
  email: string;
}
const PopupOTP: React.FC<PropsType> = ({
  setPopUp,
  handleTabChange,
  email,
}) => {
  const [otp, setOtp] = useState<string[]>(new Array(6).fill(""));
  const [verify, { loading }] = useVerifyEmailOtpMutation();
  const { user, setUser } = useUserContext();
  const [popUpStep, setPopUpStep] = useState("popup 1");

  const handleClick = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (user?.email) {
      const response = await verify({
        variables: { email: user?.email, otpCode: otp.join("") },
      });
      if (
        response.data?.verifyEmailOTP.user &&
        response.data?.verifyEmailOTP.token
      ) {
        setUser({
          ...user,
          ...response.data?.verifyEmailOTP.user,
        });
        localStorage.setItem(
          SESSION_TOKEN,
          response.data?.verifyEmailOTP.token
        );
        if (handleTabChange !== undefined) handleTabChange("Tab 2");
      }
    }
  };
  return (
    <div className='fixed top-0 bottom-0 right-0 left-0 bg-[#00000055] flex items-center justify-center'>
      {popUpStep === "popup 1" ? (
        <div className='relative w-fit max-w-sm flex flex-col items-center gap-5 bg-white rounded-lg p-10'>
          <button
            className='absolute top-2 right-2 p-1 bg-mms-green-medium border-[0.5px] border-gray-500 rounded-full hover:bg-mms-green-semi-dark active:bg-mms-green-dark disabled:bg-mms-gray-disabled'
            onClick={() => setPopUp(false)}
          >
            <XMarkIcon className='size-4 text-white' />
          </button>
          <div
            className={`w-full col-span-5 bg-mms-custom-pattern-otp bg-no-repeat bg-cover bg-right bg-mms-green-medium text-white text-sm py-5 px-6 rounded-md text-center`}
          >
            <h3 className='font-bold text-2xl'>Verify Email</h3>
          </div>
          <p className='text-sm'>
            A verification code will be sent to the email
            <span className='text-mms-orange-medium'> {email}</span> . Please
            check your inbox for the verification code.
          </p>
          <Button
            label='Proceed'
            type='submit'
            onClick={() => setPopUpStep("popup 2")}
            containerStyles='bg-mms-green-medium hover:bg-mms-green-semi-dark active:bg-mms-green-dark disabled:bg-mms-gray-disabled'
            textStyles='text-white text-sm'
          />
        </div>
      ) : (
        <form
          onSubmit={handleClick}
          className='relative w-fit flex flex-col items-center gap-10 bg-white rounded-lg p-10'
        >
          <button
            className='absolute top-2 right-2 p-1 bg-mms-green-medium border-[0.5px] border-gray-500 rounded-full hover:bg-mms-green-semi-dark active:bg-mms-green-dark disabled:bg-mms-gray-disabled'
            onClick={() => setPopUp(false)}
          >
            <XMarkIcon className='size-4 text-white' />
          </button>
          <div
            className={`max-w-sm col-span-5 bg-mms-custom-pattern-otp bg-no-repeat bg-cover bg-right bg-mms-green-medium text-white text-sm py-5 px-6 rounded-md text-center`}
          >
            <h3 className='font-bold text-2xl'>Enter OTP</h3>
            <p className='text-xs'>
              Please check your email for the OTP that was sent to you.
            </p>
          </div>
          <div className='flex justify-center'>
            <OTPInput otp={otp} setOtp={setOtp} />
          </div>
          <Button
            label='Verify'
            loading={loading}
            type='submit'
            loadingText='Verifying'
            containerStyles='bg-mms-green-medium hover:bg-mms-green-semi-dark active:bg-mms-green-dark disabled:bg-mms-gray-disabled'
            textStyles='text-white text-sm'
          />
        </form>
      )}
    </div>
  );
};

export default PopupOTP;
