import { useState } from "react";
import PaymentAccountList, {
  accountDetailsTypes,
} from "./payment_account_tabs/PaymentAccountList";
import AddPaymentAccount from "./payment_account_tabs/AddPaymentAccount";
import UpdatePaymentMethod from "./payment_account_tabs/UpdatePaymentMethod";

const PaymentAccount: React.FC = () => {
  const [activeTab, setActiveTab] = useState("payment_account");
  const [accountDetails, setAccountDetails] = useState<accountDetailsTypes>({
    account_holder_name: "",
    bank_name: "",
    bank_account_number: "",
  });
  return (
    <>
      {activeTab === "payment_account" && (
        <PaymentAccountList
          setAccountDetails={setAccountDetails}
          setActiveTab={setActiveTab}
        />
      )}
      {activeTab === "add_payment_account" && (
        <AddPaymentAccount setActiveTab={setActiveTab} />
      )}
      {activeTab === "update_payment_account" && (
        <UpdatePaymentMethod
          accountDetails={accountDetails}
          setActiveTab={setActiveTab}
        />
      )}
    </>
  );
};

export default PaymentAccount;
