import ReviewListItem from "./ReviewListItem";

const ReviewList = () => {
  return (
    <div className='mt-5 grid grid-cols-3 gap-4 justify-start text-sm items-start'>
      <ReviewListItem />
      <ReviewListItem />
      <ReviewListItem />
      <ReviewListItem />
      <ReviewListItem />
      <ReviewListItem />
      <ReviewListItem />
      <ReviewListItem />
      <ReviewListItem />
    </div>
  );
};

export default ReviewList;
