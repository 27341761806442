import { useState } from "react";
import { NavLink } from "react-router-dom";

interface PropsType {
  Icon: any;
  label: string;
  pathname: string;
  expanded: boolean;
}
const NavItem: React.FC<PropsType> = ({ Icon, pathname, label, expanded }) => {
  const [hovered, setHovered] = useState(false);
  return (
    <NavLink
      to={pathname}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className={({ isActive }) =>
        `relative transition-all flex gap-3 text-sm items-center p-2 cursor-pointer hover:bg-mms-green-semi-light pl-7 rounded-r-md group hover:text-mms-green-dark font-normal ${
          isActive && "bg-mms-green-semi-light"
        }`
      }
    >
      <Icon className='w-full h-full max-w-5 min-w-5' />
      <span
        className={`transition-all text-nowrap ${
          expanded ? "text-sm" : "text-[0px]"
        }`}
      >
        {label}
      </span>
      {!expanded && hovered && (
        <div
          className={`fixed text-nowrap left-14 rounded-md px-2 py-1 ml-6 bg-mms-green-light text-mms-green-dark text-sm invisible opacity-20 -translate-x-3 transition-all group-hover:visible group-hover:opacity-100 group-hover:translate-x-0`}
        >
          {label}
        </div>
      )}
    </NavLink>
  );
};

export default NavItem;
