import TopProductSold from "components/TopProductSold";
import React from "react";

const TopProductSoldSection = () => {
  return (
    <div className='grid grid-cols-12 gap-5 mt-5'>
      <TopProductSold />
      <div className='col-span-5 bg-white rounded-lg shadow-sm pt-7 pb-5 pr-4 pl-6'>
        <div className='flex items-center gap-5 text-xs'>
          <h3 className='font-bold'>Sales Mapping by Country</h3>
        </div>
      </div>
    </div>
  );
};

export default TopProductSoldSection;
