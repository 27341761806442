import React from "react";
import ChatProfilePicture from "./ChatProfilePicture";

const MessageChatDisplaySender = () => {
  return (
    <div className='flex items-start gap-2 place-self-end max-w-[48%]'>
      <div className=''>
        <p className='w-full p-2 rounded-lg text-gray-600 text-xs bg-mms-orange-trans-light'>
          Hello, your order was sent out about 3days ago, can you please confirm
          if you have received yur orders
        </p>
        <p className='text-xs font-light text-gray-400 mt-1 text-end'>
          3:25 pm
        </p>
      </div>
      <div className=''>
        <ChatProfilePicture type='sender' />
      </div>
    </div>
  );
};

export default MessageChatDisplaySender;
