import { gigsmarket } from "assets/images";

const GigsMarketBanner = () => {
  return (
    <div className='mt-5'>
      <img src={gigsmarket} alt='gigs market banner' />
    </div>
  );
};

export default GigsMarketBanner;
