import { BuildingStorefrontIcon } from "@heroicons/react/24/outline";
import TopProductSold from "./TopProductSold";

const DashboardProductSold = () => {
  return (
    <div className='grid grid-cols-12 gap-5 mt-5'>
      <TopProductSold />
      <div className='col-span-5 bg-white rounded-lg shadow-sm pt-7 pb-5 pr-4 pl-6'>
        <BuildingStorefrontIcon className='size-16 text-mms-green-medium' />
        <h3 className='font-bold text-6xl mt-2'>400</h3>
        <p className='font-semibold text-lg text-gray-500 mt-3'>
          New Product & Service Request In Market
        </p>
      </div>
    </div>
  );
};

export default DashboardProductSold;
