import { SVGProps } from "react";
const SwitchOffIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={40}
    height={20}
    fill='none'
    {...props}
  >
    <g clipPath='url(#a)'>
      <rect
        width={39.5}
        height={19.5}
        x={0.25}
        y={0.25}
        fill='#F2F2F2'
        stroke='#E5E5E5'
        strokeWidth={0.5}
        rx={9.75}
      />
      <g filter='url(#b)'>
        <rect width={16} height={16} x={2} y={2} fill='#fff' rx={8} />
      </g>
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M0 0h40v20H0z' />
      </clipPath>
      <filter
        id='b'
        width={18}
        height={18}
        x={2}
        y={2}
        colorInterpolationFilters='sRGB'
        filterUnits='userSpaceOnUse'
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          result='hardAlpha'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
        />
        <feMorphology
          in='SourceAlpha'
          radius={1}
          result='effect1_dropShadow_880_15108'
        />
        <feOffset dx={1} dy={1} />
        <feGaussianBlur stdDeviation={1} />
        <feColorMatrix values='0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.3 0' />
        <feBlend
          in2='BackgroundImageFix'
          result='effect1_dropShadow_880_15108'
        />
        <feBlend
          in='SourceGraphic'
          in2='effect1_dropShadow_880_15108'
          result='shape'
        />
      </filter>
    </defs>
  </svg>
);
export default SwitchOffIcon;
