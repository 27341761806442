import {
  EllipsisVerticalIcon,
  FaceSmileIcon,
} from "@heroicons/react/24/outline";
import MessageChatDisplayRecepient from "./MessageChatDisplayRecepient";
import ChatProfilePicture from "./ChatProfilePicture";
import MessageChatDisplaySender from "./MessageChatDisplaySender";
import Button from "./Button";
import MessageTextArea from "./MessageTextArea";

const MessageChatListDisplay = () => {
  return (
    <>
      <div className='flex-grow col-span-8 border-[0.5px] border-gray-300 bg-white rounded-md flex flex-col'>
        <div className='flex items-center justify-between p-3 border-b-[0.5px] border-gray-300'>
          <div className='flex items-center'>
            <ChatProfilePicture />
            <div className='col-span-7 ml-2'>
              <p className='font-bold text-sm'>Tosin Makinde Stores</p>
              <p className='font-light text-xs text-mms-green-medium'>Online</p>
            </div>
          </div>
          <div className=''>
            <EllipsisVerticalIcon className='size-6' />
          </div>
        </div>
        <div className='flex-grow flex flex-col justify-between relative'>
          <div className='flex flex-col p-5'>
            <MessageChatDisplayRecepient />
            <MessageChatDisplaySender />
            <MessageChatDisplayRecepient />
            <MessageChatDisplaySender />
          </div>
          <div className='sticky bottom-0 border-[0.5px] border-gray-300 bg-white flex items-center p-3 max overflow-hidden'>
            <MessageTextArea />
            <FaceSmileIcon className='size-6' />
            <EllipsisVerticalIcon className='size-6' />
            <Button
              label='Send Message'
              loading={false}
              containerStyles='bg-mms-green-medium hover:bg-mms-green-semi-dark active:bg-mms-green-dark disabled:bg-mms-gray-disabled'
              textStyles='text-white text-xs whitespace-nowrap'
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MessageChatListDisplay;
