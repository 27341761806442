import NotificationListItem from "./NotificationListItem";

const NotificationsList = () => {
  return (
    <div className='mt-3 grid grid-cols-12 flex-col'>
      <NotificationListItem />
      <NotificationListItem />
      <NotificationListItem />
    </div>
  );
};

export default NotificationsList;
