import React, { SVGProps } from "react";

const SwitchOnIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='40'
      height='20'
      viewBox='0 0 40 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_2536_35784)'>
        <rect
          x='0.25'
          y='0.25'
          width='39.5'
          height='19.5'
          rx='9.75'
          fill='#4E8AFF'
          stroke='#E5E5E5'
          stroke-width='0.5'
        />
        <g filter='url(#filter0_d_2536_35784)'>
          <rect x='22' y='2' width='16' height='16' rx='8' fill='white' />
        </g>
      </g>
      <defs>
        <filter
          id='filter0_d_2536_35784'
          x='22'
          y='2'
          width='18'
          height='18'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology
            radius='1'
            operator='erode'
            in='SourceAlpha'
            result='effect1_dropShadow_2536_35784'
          />
          <feOffset dx='1' dy='1' />
          <feGaussianBlur stdDeviation='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.3 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_2536_35784'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_2536_35784'
            result='shape'
          />
        </filter>
        <clipPath id='clip0_2536_35784'>
          <rect width='40' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SwitchOnIcon;
