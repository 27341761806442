import { CheckBadgeIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { withdrawal } from "assets/images";
import Button from "./Button";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "sonner";

interface PropsType {
  setPopUp: (bool: boolean) => void;
  setIsSuccess: (bool: boolean) => void;
}

const initialValues: any = {
  amount: "",
};

const validationSchema = Yup.object({
  amount: Yup.number().required("Required"),
});

const WithdrawalFromWalletPopUp: React.FC<PropsType> = ({
  setPopUp,
  setIsSuccess,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        toast.success("submitted!");
        setIsSuccess(true);
        setPopUp(false);
      }}
    >
      {({ errors }) => (
        <Form className='fixed top-0 bottom-0 right-0 left-0 bg-[#00000055] flex items-center justify-center'>
          <div className='relative w-2/5 flex flex-col items-center gap-5 bg-white rounded-lg p-10'>
            <button
              className='absolute top-2 right-2 p-1 bg-mms-green-medium border-[0.5px] border-gray-500 rounded-full hover:bg-mms-green-semi-dark active:bg-mms-green-dark disabled:bg-mms-gray-disabled'
              onClick={() => setPopUp(false)}
            >
              <XMarkIcon className='size-4 text-white' />
            </button>
            <div
              className={`w-full col-span-5 bg-mms-custom-pattern-otp bg-no-repeat bg-cover bg-right bg-mms-green-medium text-white text-sm py-5 px-6 rounded-md text-center`}
            >
              <h3 className='font-bold text-2xl'>Withdraw Funds</h3>
              <p className='text-xs'>How much do you want to withdraw?</p>
            </div>
            <div className='w-full flex flex-col gap-5'>
              <div>
                <div className='flex flex-col gap-1 w-full'>
                  <label htmlFor='amount' className='text-sm'>
                    Amount
                  </label>
                  <div className='flex items-center border border-gray-300 rounded-lg w-full bg-white'>
                    <Field
                      type='number'
                      id='amount'
                      name='amount'
                      className='outline-none p-3 rounded-lg w-full bg-transparent'
                      placeholder='N 20,000'
                    />
                  </div>
                </div>
                {errors.amount && (
                  <ErrorMessage
                    name='amount'
                    component='div'
                    className='text-red-500 text-sm'
                  />
                )}
              </div>
              <div className='flex items-center gap-3 border border-gray-300 p-5 rounded-lg'>
                <CheckBadgeIcon className='size-10 text-mms-orange-medium' />
                <p className='text-sm '>
                  Your funds will be sent to your Gtbank 0053618756. Tap to
                  change
                </p>
              </div>
              <Button
                label='Withdrawal Funds'
                iconImage={withdrawal}
                loadingText='Withdrawing...'
                type='submit'
                containerStyles='w-fit bg-mms-green-medium mt-3 hover:bg-mms-green-semi-dark active:bg-mms-green-dark disabled:bg-mms-gray-disabled self-center'
                textStyles='text-white'
                onClick={() => {}}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default WithdrawalFromWalletPopUp;
