import { ErrorMessage, Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import FormField from "./FormField";

import {
  LoginMutationVariables,
  useLoginMutation,
} from "__generated__/graphql";
import { SESSION_TOKEN } from "context/AppContext";
import { useUserContext } from "context/UserContext";
import { useNavigate } from "react-router-dom";
import SwitchOffIcon from "../assets/icons/SwitchOffIcon";
import Button from "./Button";
import SwitchOnIcon from "assets/icons/SwitchOnIcon";

const initialValues: LoginMutationVariables = {
  email: "",
  password: "",
};

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Required"),
});

const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const { user, setUser } = useUserContext();
  const [login, { loading, error }] = useLoginMutation();
  const [rememberMe, setRememberMe] = useState(false);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        const response = await login({
          variables: {
            email: values.email,
            password: values.password,
          },
        });
        if (response.data?.login.user && response.data?.login.token) {
          setUser({
            ...user,
            ...response.data?.login.user,
          });
          localStorage.setItem(SESSION_TOKEN, response.data?.login.token);
          navigate("/");
        }
        actions.setSubmitting(false);
      }}
    >
      {({ isSubmitting }) => (
        <Form className='flex flex-col w-full gap-3'>
          <div>
            <FormField
              type='email'
              id='email'
              name='email'
              label='Email'
              labelStyle='pl-3'
              placeholder='Enter email'
            />
            <ErrorMessage
              name='email'
              component='div'
              className='text-red-500 text-sm'
            />
          </div>

          <div>
            <FormField
              id='password'
              name='password'
              label='Password'
              type='password'
              labelStyle='pl-3'
              placeholder='Enter password'
            />
            <ErrorMessage
              name='password'
              component='div'
              className='text-red-500 text-sm'
            />
          </div>
          {error && <p className='text-sm text-red-500'>{error?.message}</p>}
          <div className='flex items-center justify-between text-sm'>
            <div className='flex items-center gap-2'>
              {rememberMe ? (
                <SwitchOffIcon className='cursor-pointer' />
              ) : (
                <SwitchOnIcon className='cursor-pointer' />
              )}
              <span>Remember me</span>
            </div>
            <div className='text-mms-blue-medium cursor-pointer'>
              Forgot password?
            </div>
          </div>
          <Button
            label='Sign in'
            loading={loading}
            loadingText='Signing In'
            type='submit'
            containerStyles='w-full bg-mms-green-medium hover:bg-mms-green-semi-dark active:bg-mms-green-dark disabled:bg-mms-gray-disabled'
            textStyles='text-white'
          />
          <div className='text-center text-sm'>
            Don't have an account?{" "}
            <span
              className='text-mms-blue-medium cursor-pointer'
              onClick={() => navigate("/register")}
            >
              Sign up now
            </span>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
