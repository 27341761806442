import { SUPPORT_QUESTIONS } from "helpers";
import SupportQuestion from "./SupportQuestion";

interface PropsType {
  activeTab: number;
  handleTabClick: (text: number) => void;
}

const SupportQuestionNavigation: React.FC<PropsType> = ({
  activeTab,
  handleTabClick,
}) => {
  return (
    <div className='col-span-6 bg-white h-fit sticky top-10 left-20'>
      {SUPPORT_QUESTIONS?.map((question, index) => (
        <SupportQuestion
          index={index}
          defaultStyle='hover:bg-mms-green-extra-light text-sm'
          activeStyle='bg-mms-green-extra-light cursor-not-allowed'
          activeTab={activeTab}
          label={question.question}
          handleTabClick={handleTabClick}
        />
      ))}
    </div>
  );
};

export default SupportQuestionNavigation;
