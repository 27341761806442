import React from "react";
import GigsListItem from "./GigsListItem";

const CancelledGigsList = () => {
  return (
    <div className='flex flex-col gap-3 mt-5'>
      <GigsListItem cancelled={true} />
      <GigsListItem cancelled={true} />
    </div>
  );
};

export default CancelledGigsList;
