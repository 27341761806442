import GeneralTopSearchWithNotification from "components/GeneralTopSearchWithNotification";
import NavigationSideBar from "components/NavigationSideBar";
import { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import AddNewProduct from "./AddNewProduct";
import Ads from "./Ads";
import BoostProduct from "./BoostProduct";
import Dashboard from "./Dashboard";
import EditProduct from "./EditProduct";
import GigsMarket from "./GigsMarket";
import Messages from "./Messages";
import Notifications from "./Notifications";
import Orders from "./Orders";
import Products from "./Products";
import Reviews from "./Reviews";
import SalesReport from "./SalesReport";
import Settings from "./Settings";
import Support from "./Support";
import SupportChat from "./SupportChat";
import Wallet from "./Wallet";

const Index = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/dashboard");
  }, []);
  return (
    <div className=''>
      <GeneralTopSearchWithNotification />
      <div className='relative flex'>
        <NavigationSideBar />
        <Routes>
          <Route path={"/dashboard"} element={<Dashboard />} />
          <Route path={"/products"}>
            <Route index={true} element={<Products />} />
            <Route path={"add"} element={<AddNewProduct />} />
            <Route path={"edit"} element={<EditProduct />} />
            <Route path={"boost"} element={<BoostProduct />} />
          </Route>
          <Route path={"/sales-report"} element={<SalesReport />} />
          <Route path={"/wallet"} element={<Wallet />} />
          <Route path={"/reviews"} element={<Reviews />} />
          <Route path={"/gigs-market"} element={<GigsMarket />} />
          <Route path={"/messages"} element={<Messages />} />
          <Route path={"/notifications"} element={<Notifications />} />
          <Route path={"/orders"} element={<Orders />} />
          <Route path={"/ads"} element={<Ads />} />
          <Route path={"/support"}>
            <Route index={true} element={<Support />} />
            <Route path='chat' element={<SupportChat />} />
          </Route>
          <Route path={"/settings"} element={<Settings />} />
        </Routes>
      </div>
    </div>
  );
};

export default Index;
