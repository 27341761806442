import {
  BuildingStorefrontIcon,
  ChartBarIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  ChatBubbleOvalLeftIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  HomeIcon,
  MegaphoneIcon,
  ShoppingBagIcon,
  ShoppingCartIcon,
  UserGroupIcon,
  WalletIcon,
} from "@heroicons/react/24/outline";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "./Button";
import NavItem from "./NavItem";

const NavigationSideBar: React.FC = () => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(true);
  return (
    <div
      className={`z-10 flex flex-col pb-5 h-screen overflow-y-auto custom-scrollbar sticky top-0 left-0 pr-5 border-r border-gray-300 bg-white gap-2 pt-2 transition-all duration-300 ${
        expanded ? "max-w-[15%] w-full" : "max-w-[7%] w-full"
      }`}
    >
      <div
        className='min-w-7 text-mms-green-medium self-end p-1 rounded-md bg-mms-gray-light cursor-pointer hover:bg-mms-green-extra-light'
        onClick={() => setExpanded((isExpanded) => !isExpanded)}
      >
        {expanded ? <ChevronDoubleLeftIcon /> : <ChevronDoubleRightIcon />}
      </div>
      <div className=' space-y-2 group'>
        <NavItem
          expanded={expanded}
          Icon={HomeIcon}
          pathname='/dashboard'
          label='Dashboard'
        />
        <NavItem
          expanded={expanded}
          pathname='/products'
          Icon={ShoppingBagIcon}
          label='Products'
        />
        {/* <NavItem expanded={expanded} pathname='/services' Icon={ShoppingBagIcon} label='Services' /> */}
        <NavItem
          expanded={expanded}
          pathname='/sales-report'
          Icon={ChartBarIcon}
          label='Sales Report'
        />
        <NavItem
          expanded={expanded}
          pathname='/wallet'
          Icon={WalletIcon}
          label='Wallet'
        />
        <NavItem
          expanded={expanded}
          pathname='/orders'
          Icon={ShoppingCartIcon}
          label='Orders'
        />
        <NavItem
          expanded={expanded}
          pathname='/messages'
          Icon={ChatBubbleOvalLeftIcon}
          label='Messages'
        />
        <NavItem
          expanded={expanded}
          pathname='/gigs-market'
          Icon={BuildingStorefrontIcon}
          label='Gigs Markets'
        />
        <NavItem
          expanded={expanded}
          pathname='/ads'
          Icon={MegaphoneIcon}
          label='Ads'
        />
        <NavItem
          expanded={expanded}
          pathname='/reviews'
          Icon={UserGroupIcon}
          label='Reviews'
        />
        <NavItem
          expanded={expanded}
          pathname='/support'
          Icon={ChatBubbleOvalLeftEllipsisIcon}
          label='Live Support'
        />
      </div>
      {/* <NavItem expanded={expanded} Icon={Cog6ToothIcon} pathname='/settings' label='Settings' /> */}
      {/* <NavItem expanded={expanded}
        Icon={ChatBubbleLeftRightIcon}
        pathname='/support'
        label='Support'
      /> */}
      {/* <LogOut /> */}
      <div
        className={`transition-all overflow-hidden space-y-2 ${
          expanded ? "w-auto" : "w-0"
        }`}
      >
        <p className='text-mms-green-medium text-sm pl-7 mt-2'>
          Switch to buyer
        </p>
        <div className='flex ml-7 flex-col items-center gap-2 bg-mms-custom-pattern-upgrade bg-no-repeat bg-cover px-2 py-4 rounded-lg'>
          <p className='text-white text-xs text-center'>
            Upgrade your business account to get access to more features
          </p>
          <Button
            label='Coming soon'
            disabled
            containerStyles='bg-white hover:bg-mms-green-light active:bg-mms-green-medium disabled:bg-mms-gray-disabled'
            textStyles='text-mms-green-dark text-xs'
            onClick={() => navigate("/settings")}
          />
        </div>
      </div>
    </div>
  );
};

export default NavigationSideBar;
