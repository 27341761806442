import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { toast } from "sonner";

function Budget() {
  const [duration, setDuration] = useState(10);
  const handleDuration = (sign: string) => {
    if (sign === "+") {
      setDuration((prev) => prev + 1);
    } else if (sign === "-") {
      setDuration((prev) => prev - 1);
    } else {
      toast.error("Unknown arithmetic");
    }
  };
  return (
    <div className='bg-white p-5 flex flex-col gap-5'>
      <div>
        <h3 className='font-bold text-xl'>Budget</h3>
        <div className='mt-2 border border-mms-green-Ash rounded-md p-4'>
          <div className='flex items-center justify-between gap-5'>
            <p className='font-medium'>Subscription package</p>
            <input
              name='package_type'
              type='radio'
              className='w-5 h-5 accent-mms-green-medium'
            />
          </div>
          <div className='mt-3 flex gap-5 items-center justify-between'>
            <div className='flex items-center gap-3 p-2 rounded-md border border-mms-green-Ash bg-mms-green-light'>
              <p>₦500</p>
              <p className='bg-mms-green-medium opacity-50 text-white py-.5 px-2 rounded-sm'>
                1 Day
              </p>
              <input
                value={"1"}
                name='package'
                type='radio'
                className='w-5 h-5 accent-mms-green-medium'
              />
            </div>
            <div className='flex items-center gap-3 p-2 rounded-md border border-mms-green-Ash bg-mms-green-light'>
              <p>₦3,500</p>
              <p className='bg-mms-green-medium opacity-50 text-white py-.5 px-2 rounded-sm'>
                7 Days
              </p>
              <input
                value={"7"}
                name='package'
                type='radio'
                className='w-5 h-5 accent-mms-green-medium'
              />
            </div>
            <div className='flex items-center gap-3 p-2 rounded-md border border-mms-green-Ash bg-mms-green-light'>
              <p>₦15,000</p>
              <p className='bg-mms-green-medium opacity-50 text-white py-.5 px-2 rounded-sm'>
                30 Days
              </p>
              <input
                value={"30"}
                type='radio'
                name='package'
                className='w-5 h-5 accent-mms-green-medium border-mms-green-Ash'
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <h3 className='font-bold text-xl'>Set Duration</h3>
        <div className='mt-2 space-y-10 border border-mms-green-Ash rounded-md p-4'>
          <div className='flex items-center justify-between gap-5'>
            <p className='font-medium'>
              Manually set a duration based on your budget
            </p>
            <input
              name='package_type'
              type='radio'
              className='w-5 h-5 accent-mms-green-medium'
            />
          </div>
          <div className='flex items-center justify-center gap-5'>
            <div
              className='w-fit border border-mms-green-Ash rounded-md p-3 cursor-pointer hover:bg-gray-100 active:p-2'
              onClick={() => handleDuration("-")}
            >
              <MinusIcon className='w-4' />
            </div>
            <div className='w-fit border border-mms-green-Ash rounded-md p-3 font-bold text-mms-green-medium text-xl'>
              {duration}
            </div>
            <div className='w-fit border border-mms-green-Ash rounded-md p-3 hover:bg-gray-100 active:p-2'>
              <PlusIcon className='w-4' onClick={() => handleDuration("+")} />
            </div>
          </div>
          <p className='text-center font-bold text-mms-green-medium text-2xl'>
            ₦{(duration * 500).toLocaleString()}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Budget;
