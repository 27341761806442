import { ShoppingBagIcon } from "@heroicons/react/24/outline";
import TodaySales from "./TodaySales";

const DashboardSalesReport = () => {
  return (
    <div className='grid grid-cols-12 gap-5 mt-5'>
      <TodaySales />
      <div className='col-span-5 bg-white rounded-lg shadow-sm pt-7 pb-5 pr-4 pl-6'>
        <ShoppingBagIcon className='size-16 text-mms-green-medium' />
        <h3 className='font-bold text-6xl mt-2'>25</h3>
        <p className='font-semibold text-lg text-gray-500 mt-3'>
          Numbers of product in store
        </p>
      </div>
    </div>
  );
};

export default DashboardSalesReport;
