import UserContextProvider from "context/UserContextProvider";
import Index from "pages/Index";
import NotFound from "pages/NotFound";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Toaster } from "sonner";
import { AppContextProvider } from "./context/AppContext";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";

function App() {
  return (
    <AppContextProvider>
      <Toaster />
      <UserContextProvider>
        <BrowserRouter>
          <Routes>
            {/* <Route index={true} element={<MainPage />} /> */}
            <Route path={"/*"} element={<Index />} />
            {/*end- Protected routes */}

            {/*start- auth routes */}
            <Route path={"/login"} element={<Login />} />
            <Route path={"/register"} element={<Register />} />
            {/*end- auth routes */}

            <Route path={"*"} element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </UserContextProvider>
    </AppContextProvider>
  );
}

export default App;
