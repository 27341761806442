import React, { useState, useRef, useEffect } from "react";

const MessageTextArea: React.FC = () => {
  const [text, setText] = useState("");
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
  };

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto"; // Reset height to auto to calculate the new height
      textarea.style.height = `${textarea.scrollHeight}px`; // Set the new height
    }
  }, [text]);

  return (
    <textarea
      ref={textareaRef}
      value={text}
      onChange={handleChange}
      className='w-full max-h-40 text-sm p-2 resize-none overflow-auto focus:outline-none'
      placeholder='Write a message'
      rows={1}
    />
  );
};

export default MessageTextArea;
