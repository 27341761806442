import CustomArrow from "assets/icons/CustomArrow";
import Button from "../Button";

interface PropsType {
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
}
const OrderButton: React.FC<PropsType> = ({ activeTab, setActiveTab }) => {
  return (
    <div className='flex items-center justify-between mt-5'>
      <div className='flex items-center gap-3'>
        <Button
          isActive={activeTab === "Completed"}
          inActiveStyles='bg-white border border-mms-green-dark'
          inActiveTextStyles='text-mms-green-dark text-sm'
          onClick={() => setActiveTab("Completed")}
          label='Completed'
          containerStyles='bg-mms-green-medium hover:bg-mms-green-semi-dark active:bg-mms-green-dark disabled:bg-mms-gray-disabled'
          textStyles='text-white text-sm'
        />
        <Button
          isActive={activeTab === "Shipped"}
          inActiveStyles='bg-white border border-mms-green-dark'
          inActiveTextStyles='text-mms-green-dark text-sm'
          onClick={() => setActiveTab("Shipped")}
          label='Shipped'
          containerStyles='bg-mms-green-medium hover:bg-mms-green-semi-dark active:bg-mms-green-dark disabled:bg-mms-gray-disabled'
          textStyles='text-white text-sm'
        />
        <Button
          isActive={activeTab === "New Order"}
          inActiveStyles='bg-white border border-mms-green-dark'
          inActiveTextStyles='text-mms-green-dark text-sm'
          onClick={() => setActiveTab("New Order")}
          label='New Order'
          containerStyles='bg-mms-green-medium hover:bg-mms-green-semi-dark active:bg-mms-green-dark disabled:bg-mms-gray-disabled'
          textStyles='text-white text-sm'
        />
      </div>
      <div className='relative min-w-20'>
        <select className='block w-full appearance-none bg-white border border-mms-green-semi-light text-mms-green-medium py-2 pl-2 pr-10 rounded-lg shadow-sm focus:outline-none'>
          <option>Date</option>
          <option>Option 2</option>
          <option>Option 3</option>
        </select>
        <CustomArrow />
      </div>
    </div>
  );
};

export default OrderButton;
